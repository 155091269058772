document.addEventListener("livewire:load", function (event) {

    window.addEventListener('setValidations', e => {

        switch (e.detail.rol) {
            case 'is_dirGral':
                $('.fields .ignore.dirgral').attr('disabled', true);
                break;

            case 'is_responsable':
                $('.fields .st-input').attr('disabled', true);
                $('.fields #id_archivo_licencia').attr('disabled', false);
                $('.fields #fh_expedicion').attr('disabled', false);
                break;

            case 'is_admin':
                $('.fields .st-input').attr('disabled', true);
                break;
        }
    })

    window.addEventListener('open-modal', e => {
        let estatus = e.detail.estatus ? 'activó' : 'inactivó';
        $('#email').html(e.detail.email);
        $('#estatus').html(estatus);
        $('#' + e.detail.name).modal('show');
    });

});

document.addEventListener("DOMContentLoaded", () => {
    // Livewire.hook('component.initialized', (component) => {
    //     if (component.fingerprint.name == 'modalidades.form-persona-finada-fosa-comun-si' || 'modalidades.form-persona-finada-fosa-comun-no') {
    //         if (component.serverMemo.data.formulario.fh_ingreso == null) {
    //             $('.cont-dp input').datepicker();
    //         }
    //     }
    // })
});
