if ($('#frm_basica').length) {
    var dislugares = {
        tp_espacio: {
            header: 'Tipo de lugar',
            'class': 'font-weight-bold'
        },
        id_cementerio: {
            header: 'id_cementerio',
            'class': 'd-none'
        },
        id_tipo_espacio: {
            header: 'id_espacio',
            'class': 'd-none'
        },
        nu_limite: {
            header: 'Límite',
            'class': 'font-weight-bold'
        },
        nu_ocupa_inicial: {
            header: 'Ocupación Inicial',
            'class': 'font-weight-bold'
        },
        nu_ocupa_actual: {
            header: 'Ocupación Actual',
            'class': 'font-weight-bold'
        },
        nu_disponibilidad: {
            header: 'Disponibilidad',
            'class': 'font-weight-bold'
        },
        ver: {
            header: 'Acciones',
            'armacol': 'arma_botones(tr)'
        }
    };

    var data = new Array();

    $('#regresa').on('click', function (e) {
        window.location.href = ubase + "/cementerios";
    });

    $('#estatus').on('click', function (e) {
        e.preventDefault();
        $('.error_modalidad').addClass('d-none');
        var forma = $('#frm_fisica')[0];
        var distri = $('#dg_lugares_body').find('tr').length;
        var areas = $('.areas').find('div').length;
        var archivos = $('input[type="file"].required:not([data-archivo])').length;
        if (forma.checkValidity() === false || distri == 0 || areas == 0 || archivos != 0) {
            forma.classList.add('was-validated');
            if (distri == 0) {
                $('.error_distribucionespacios').removeClass('d-none');
            }
            if (areas == 0) {
                $('.error_agregararea').removeClass('d-none');
            }
            if (archivos != 0) {
                $('input[type="file"].required:not([data-archivo])').parent().next().removeClass('d-none');
            }
            return;
        }

        upd_dato(e);
    });

    $('#cancelar, #btn_cancelar').on('click', function (e) {
        e.preventDefault();
        window.location.href = ubase + "/cementerios";
    });

    $('.btn-menos').on('click', function (e) {
        baja_area(this);
    });

    $('input[name="st_pertenece_grupo"], #id_concesionario, #id_tipo_construccion, #nu_hornos, #id_agencia_funeraria').on('change', function (e) {
        upd_dato(e);
    });

    $('select[name^="hm_"], input[name^="st_full"]').on('change', function (e) {
        $('.error_horario').addClass('d-none');
        if (e.target.id.indexOf('abre') != -1) {
            var abre = e.target.value.replace(':', '');
            scierra = e.target.id.replace('abre', 'cierra');
            var cierra = $('#' + scierra).val();
            cierra = cierra.replace(':', '');
            if (parseInt(abre) > parseInt(cierra) & parseInt(cierra) != 0) {
                error_horario(e);
                e.preventDefault();
                return;
            }
        }
        if (e.target.id.indexOf('cierra') != -1) {
            var cierra = e.target.value.replace(':', '');
            sabre = e.target.id.replace('cierra', 'abre');
            var abre = $('#' + sabre).val();
            abre = abre.replace(':', '');
            console.log('cierra=' + cierra + ' abre=' + abre);
            if (parseInt(abre) > parseInt(cierra)) {
                error_horario(e);
                e.preventDefault();
                return;
            }
        }

        upd_dato(e, 'cementerio_horario');
    });

    $('#id_alcaldia').on('change', function (e) {
        if (e.target.value != '') {
            $('#id_cementerio').removeAttr('disabled');
            $('#datos_cementerio').addClass('d-none');
            dame_cementerioxAlc();
        } else {
            $('#div_cementerio').addClass('d-none');
            $('#div_cementerio input').attr('disabled', true);
            $('#div_cementerio select').attr('disabled', true);
        }
    });

    $('#id_concesionario').on('change', function (e) {
        dame_agencias();
    });

    $('input[name="modalidades"]').on('change', function (e) {
        modalidades(e);
    });

    $('#id_cementerio').on('change', function (e) {
        muestra_cementerio();
    });

    $('.fa-caret-square-down').on('click', function (e) {
        var anterior = $(this).prev();
        $(anterior).attr('size', $(anterior).children('option').length)
    });

    $('#agregarLugar').on('click', function (e) {
        e.preventDefault();
        $('#id_tipo_espacio').prop('disabled', false);
        $('#frm_lugarfisico')[0].reset();
        $('#frm_lugarfisico').removeClass('was-validated');
        $('#lugarfisico_titulo').html('Agregar lugar fisico');
        $('#lugarfisico').modal('show');
    });

    $('#btn_siguiente').on('click', function (e) {
        e.preventDefault();
        $('.error_modalidad').addClass('d-none');
        var forma = $('#frm_basica')[0];
        //var error_horario=($('select[name^="hm_"]:selected').length==0 & $('input[name^="st_full"]:checked').length==0) ? true : false ;
        var ok_horario = horarios_correctos();

        if (forma.checkValidity() === false || $('input[name="modalidades"]:checked').length == 0 || ok_horario.estatus == false) {
            forma.classList.add('was-validated');
            if ($('input[name="modalidades"]:checked').length == 0) {
                $('.error_modalidad').removeClass('d-none');
            }
            if (ok_horario.estatus == false) {
                $('.error_horario').html(ok_horario.descripcion);
                $('.error_horario').removeClass('d-none');
            }
            return;
        }

        // El cementerio se actualiza a Estatus - En edición
        update_estatus(e);
    })

    $('#guardarlugar').on('click', function (e) {
        var forma = $('#frm_lugarfisico')[0];
        if (forma.checkValidity() === false) {
            forma.classList.add('was-validated');
            return;
        }
        if (parseInt($('#nu_ocupa_inicial').val()) > parseInt($('#nu_limite').val())) {
            $('#nu_ocupa_inicial').attr('max', $('#nu_limite').val())
            forma.classList.add('was-validated');
            var div = $('#nu_ocupa_inicial').next();
            $(div).text('La ocupación inicial no puede ser mayor al límite de ocupación');
            return;
        }

        alta_lugar();
    });

    $('#agregaarea').on('click', function (e) {
        if ($('#area').val() == '') {
            $('#area').focus();
            $('.areaobli').removeClass('d-none');
            return false;
        }
        $('.areaobli').addClass('d-none');
        alta_area(this);
    });


    $('.horarioselect').on('click', function (e) {
        if ($(this).attr('size') > 1) {
            $(this).attr('size', 1);
        }
    });

    $('input[name="st_pertenece_grupo"]').change(function (e) {
        pertenecegrupo(e)
    });

    $('input[name="submenu"]').change(function (e) {
        $('input[name="submenu"]').next().removeClass('texto-verde');
        $('input[name="submenu"]:checked').next().addClass('texto-verde');
        $('div[name^="parte_"]').addClass('d-none');
        ref = $(this).data().ref;
        $('#' + ref).removeClass('d-none');
    });

    window.dame_agencias = function () {
        var agencias = _agencias.filter(x => x.id_concesionario == $('#id_concesionario option:selected').val());
        $('#id_agencia_funeraria option').remove();
        $('<option>').val("").text("Elige sucursal").appendTo('#id_agencia_funeraria');
        agencias.forEach(e => $('<option>').val(e.id).text(e.nb_agencia).appendTo('#id_agencia_funeraria'));
    }

    window.modalidades = function (e) {
        if (e.target.value != "") {
            $('.error_modalidad').addClass('d-none');
        }
        if (e.target.value == MODALIDADES.CREMACION) {
            if ($(e.target)[0].checked) {
                $('#nu_hornos').removeAttr('disabled');
                $('#nu_hornos').attr('required', true);
            } else {
                $('#nu_hornos').attr('disabled', true);
                $('#nu_hornos').removeAttr('required');
            }
        }
        upd_modalidad(e);
    }

    window.ver_lugar = function (e) {
        e.preventDefault();
        var id_cementerio = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
        var id_tipo_espacio = e.target.parentNode.parentNode.getElementsByTagName('td')[2].innerText;
        var tr = e.target.parentNode.parentNode;

        var idx = _lugares.find(x => x.id_cementerio == id_cementerio & x.id_tipo_espacio == id_tipo_espacio);
        $('#lugarfisico input').each(function (index) {
            $(this).val(idx[this.id])
        });
        $('#lugarfisico select').each(function (index) {
            $('#' + this.id + ' option[value="' + idx[this.id] + '"]').prop('selected', true);
        });
        $('#id_tipo_espacio').prop('disabled', true);
        $('#lugarfisico_titulo').html('Editar lugar físico');
        $('#lugarfisico').modal('show');
    }

    window.alta_lugar = function () {
        var Data1 = {};
        var method = 'post';
        if ($('#id_tipo_espacio').prop('disabled') == true) {
            method = 'put'
        }
        Data1['id_tipo_espacio'] = $('#id_tipo_espacio').val();
        Data1['id_cementerio'] = $('#id_cementerio').data('id');
        Data1['nu_limite'] = $('#nu_limite').val();
        Data1['nu_ocupa_inicial'] = $('#nu_ocupa_inicial').val();
        $.ajax({
            dataType: 'json',
            type: method,
            url: ubase + '/cementerioslugar',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $('.error_distribucionespacios').addClass('d-none');
                $('#lugarfisico').modal('hide');
                $('.registroexitoso').html('Registro Exitoso');
                if ($('#id_tipo_espacio').prop('disabled') == true) {
                    $('.registroexitoso').html('Cambio exitoso');
                }
                $('#registroexitoso').modal('show');
                armadatagrid(data.data, dislugares, 'dg_lugares', true);
                _lugares = data.data;
                $('.eliminarlugar').on('click', function (e) {
                    eliminarlugar(e);
                });
                $('.verlugar').on('click', function (e) {
                    ver_lugar(e);
                });
            },

            error: function (jqXhr, textStatus, errorThrown) {
                if (jqXhr.responseText.indexOf('duplicate key') != -1) {
                    $('#yaexistelugar').modal('show');
                } else {
                    console.log('sf');
                    __crearMensaje(true, "Error", "El lugar ya ha sido capturado, revise la tabla Distribucón de Espacios.");
                }
            }
        });
    }

    // Actualizar al estatus - En edición: Se replicó la función upd_dato para conservar la lógica del formulario
    window.update_estatus = function (e, tabla = 'cementerio') {

        var Data1 = {};

        Data1['estatus'] = 0;
        Data1['tabla'] = tabla;

        console.log(Data1)

        $.ajax({
            dataType: 'json',
            type: 'post',
            url: ubase + '/cementerios/' + $('#id_cementerio').data('id'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,

            success: function (data) {
                /*se cambia a la pantalla siguiente */
                $('#if').prop('checked', true);
                $('#if').trigger('change');
            },

            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 401) {
                    __crearMensaje(true, "Error", "Tu sesión ha caducado");
                }

                if (jqXHR.status === 418) {
                    __crearMensaje(true, "Error", data.mensaje || data.message);
                }

                __crearMensaje(true, "Error", 'No se pudo actualizar el estatus del cementerio');
            }
        });
    }

    window.upd_dato = function (e, tabla = 'cementerio') {
        var Data1 = {};
        var quedato = e.currentTarget.id;
        var valor = e.currentTarget.value;

        if (e.currentTarget.type == "checkbox") {
            valor = e.currentTarget.checked;
        }

        if (e.currentTarget.type == 'radio') {
            quedato = e.currentTarget.name;
        }

        Data1[quedato] = valor;
        Data1['tabla'] = tabla;
        $.ajax({
            dataType: 'json',
            type: 'post',
            url: ubase + '/cementerios/' + $('#id_cementerio').data('id'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $('#id_cementerio option:selected').data('modalidades');
                if (e.currentTarget.type == "button") {
                    $('#registroexitoso').modal('show');
                    $("#registroexitoso").on("hidden.bs.modal", function () {
                        $.redirect(ubase + "/cementerios", {
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        });
                    })
                }
            },

            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 401) {
                    __crearMensaje(true, "Error", "Tu sesión ha caducado");
                }
                if (jqXHR.status === 418) {
                    __crearMensaje(true, "Error", data.mensaje || data.message);
                }

                __crearMensaje(true, "Error", 'No se pudo actualizar el dato');
            }
        });
    }

    window.upd_modalidad = function (e) {
        $.ajax({
            dataType: 'json',
            type: 'post',
            url: ubase + '/cementerios/modalidad/' + $('#id_cementerio').data('id') + '/' + e.target.value + '/' + e.target.checked,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },

            success: function (data) {
                $('#id_cementerio option:selected').data('modalidades');
            },

            error: function (jqXhr, textStatus, errorThrown) {
                var errores = jqXhr.responseJSON.errors;
                for (var x in errores) {
                    crearMensaje(true, "Error", errores[x]);
                    break;
                }
            }
        });
    }


    window.horarios_correctos = function () {
        var regresa = {
            estatus: false,
            descripcion: 'Al menos debe definir un dia en el horario.'
        };
        /* si encuentre un valor diferente de cero es correcto */
        $('select[name^="hm_"] option:selected').each(function (index) {
            if ($(this).val() != '0:00') {
                regresa.estatus = true;
                return false;
            }
        });

        if ($('input[name^="st_full"]:checked').length > 0) {
            regresa.estatus = true;
        }

        if (regresa.estatus == true) {
            $('select[name^="hm_abre"]').each(function (index) {
                var abre = $(this).val().replace(':', '');
                var scierra = $(this)[0].id.replace('abre', 'cierra');
                var cierra = $('#' + scierra).val();
                cierra = cierra.replace(':', '');
                /* si encuentra que una apertura es mayor que el cierre hay error */
                if (parseInt(abre) > parseInt(cierra)) {
                    $(this).focus()
                    regresa.estatus = false;
                    regresa.descripcion = "La apertura es mayor que el cierre de " + $(this)[0].id.replace('hm_abre_', '');
                    return false;
                }
            });
        }

        return regresa;
    }

    window.eliminarlugar = function (e) {
        e.preventDefault();
        var id_cementerio = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
        var id_tipo_espacio = e.target.parentNode.parentNode.getElementsByTagName('td')[2].innerText;
        var ocupa_inicial = parseInt(e.target.parentNode.parentNode.getElementsByTagName('td')[4].innerText);
        var ocupa_actual = parseInt(e.target.parentNode.parentNode.getElementsByTagName('td')[5].innerText);

        if (ocupa_actual > 0) {
            $('#nopuedeeliminar').modal('show');
            return;
        }

        var tr = e.target.parentNode.parentNode;
        baja_lugar(id_cementerio, id_tipo_espacio, tr);
    }

    window.pertenecegrupo = function (e) {
        if (e.target.value == 1) {
            $('#quegrupo').removeClass('d-none');
            $('#id_concesionario').attr('required', true)
            $('#id_agencia_funeraria').attr('required', true)
        }
        if (e.target.value == 0) {
            $('#quegrupo').addClass('d-none');
            $('#id_concesionario').removeAttr('required', true)
            $('#id_agencia_funeraria').removeAttr('required', true)
        }
    }

    window.arma_botones = function (ren) {
        var edicion = '<div type="button" class="btn btn-pine-gree verlugar" >Ver</div>';
        var eliminar = '<div type="button" class="ml-1 btn btn-pine-gree eliminarlugar" >Eliminar</div>';
        return '<td class="d-flex justify-content-between cont-actions">' +
            edicion + eliminar +
            '</td>';
    }

    window.alta_area = function (e) {
        var Data1 = {};
        Data1['tx_area'] = $('#area').val();
        Data1['id_cementerio'] = $('#id_cementerio').data('id');
        $.ajax({
            dataType: 'json',
            type: 'post',
            url: ubase + '/cementeriosaltaarea',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $('.error_agregararea').addClass('d-none');
                var areanum = $('.btn-menos').length + 1;
                var areavalue = $('#area').val();
                var areahtml = '<div class="col-lg-12 form-group d-flex align-items-center pl-0" id="div_' + data.data.id + '">' +
                    '<label for="horizontal" class="control-label form-label-cdmx col-lg-2">Área ' + areanum + '</label>' +
                    '<div class="col-lg-10 d-flex flex-wrap" >' +
                    '<input class="form-control form-control-custom col-lg-8" type="text" name="area"' +
                    'id="area_' + areanum + '" value="' + areavalue + '" disabled>' +
                    '<i  id="btn-menos-' + data.data.id + '" data-id="' + data.data.id +
                    '" class="btn-menos fa fa-minus form-control d-flex justify-content-center align-items-center"></i>' +
                    '</div>' +
                    '</div>';
                $('.areas').append(areahtml);
                $('#btn-menos-' + data.data.id).on('click', function (e) {
                    baja_area(this);
                });
                $('#area').val('');
            },

            error: function (jqXhr, textStatus, errorThrown) {
                var errores = jqXhr.responseJSON.errors;
                for (var x in errores) {
                    crearMensaje(true, "Error", errores[x]);
                    break;
                }
            }
        });
    }

    window.baja_area = function (e) {
        var Data1 = {};
        Data1['id'] = e.dataset.id;
        $.ajax({
            dataType: 'json',
            type: 'delete',
            url: ubase + '/cementeriosarea',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $('#div_' + e.dataset.id).remove();
            },

            error: function (jqXhr, textStatus, errorThrown) {
                var errores = jqXhr.responseJSON.errors;
                for (var x in errores) {
                    crearMensaje(true, "Error", errores[x]);
                    break;
                }
            }
        });
    }

    window.baja_lugar = function (id_cementerio, id_tipo_espacio, tr) {
        var Data1 = {};
        Data1['id_cementerio'] = id_cementerio;
        Data1['id_tipo_espacio'] = id_tipo_espacio;
        $.ajax({
            dataType: 'json',
            type: 'delete',
            url: ubase + '/cementerioslugar',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $(tr).remove();
            },

            error: function (jqXhr, textStatus, errorThrown) {
                var errores = jqXhr.responseJSON.errors;
                for (var x in errores) {
                    crearMensaje(true, "Error", errores[x]);
                    break;
                }
            }
        });
    }


    window.muestra_cementerio = function () {
        if ($('#id_cementerio option:selected').val() == "") {
            $('#datos_cementerio').addClass('d-none');
        } else {
            $('#div_cementerio input').removeAttr('disabled');
            $('#div_cementerio select').removeAttr('disabled');
            var his = $('#id_cementerio option:selected').data('is_historico') == true ? 'Si' : 'No';
            var art = $('#id_cementerio option:selected').data('is_artistico') == true ? 'Si' : 'No';
            var selected = $('#id_cementerio option:selected');
            $('#tx_clave').html($('#id_cementerio option:selected').data('tx_clave'));
            $('#nb_clasificacion').html($('#id_cementerio option:selected').data('nb_clasificacion'));
            $('#is_historico').html(his);
            $('#is_artistico').html(art);
            $('#tx_calle').html(selected.data('tx_calle'));
            $('#tx_num_ext').html(selected.data('tx_num_ext'));
            $('#tx_codigo_postal').html(selected.data('tx_codigo_postal'));
            $('#des_colonia').html(selected.data('des_colonia'));
            $('#des_delegacion').html(selected.data('des_delegacion'));
            $('#datos_cementerio').removeClass('d-none');
            muestra_modalidades(selected.data('modalidades'));
        }
    }

    window.error_horario = function (e) {
        // $('#error_horario').remove()
        // str = '<div class="modal fade" id="error_horario" tabindex="-1" data-backdrop="static" role="dialog" aria-hidden="true">' +
        //     '<div class="modal-dialog modal-sm" role="document">' +
        //     '<div class="modal-content rounded-modal" >' +
        //     '<div class="modal-body text-center text-danger">' +
        //     'Error en el horario' +
        //     '</div>' +
        //     '<div class="modal-footer justify-content-center border-0">' +
        //     '<div type="button" class="btn btn-pine-gree" data-dismiss="modal" >Cerrar</div>' +
        //     '</div>' +
        //     '</div>' +
        //     '</div>' +
        //     '</div>';
        // $('body').append(str);
        // $('#error_horario').modal('show')
        // $("#error_horario").on("hidden.bs.modal", function () {
        //     $(e.target).focus();
        // });
    }

    window.muestra_modalidades = function (modalidades) {
        $('input[name="modalidades"]').prop('checked', false);
        $.each(modalidades, function (key, value) {
            console.log(key + ':' + value);
            $('input[name="modalidades"][value=' + value.id_modalidad + ']').prop('checked', true);
        })
    }

    armadatagrid(_lugares, dislugares, 'dg_lugares', true);
    dame_agencias();
    $('#id_agencia_funeraria option[value="' + $('#id_agencia_funeraria').data('id') + '"]').prop('selected', true);
    $('#id_file_0017').data('uuid') != '' ? crearBotonDescarga($('#id_file_0017').data('uuid'), $($('#id_file_0017')[0].parentNode)) : '';
    $('#id_file_0018').data('uuid') != '' ? crearBotonDescarga($('#id_file_0018').data('uuid'), $($('#id_file_0018')[0].parentNode)) : '';

    $('.eliminarlugar').on('click', function (e) {
        eliminarlugar(e);
    });

    $('.verlugar').on('click', function (e) {
        ver_lugar(e);
    });

}
