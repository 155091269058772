$(document).ready(function() {
	if ($('#usuariosbandeja').length) {

		 $('.st-input').css('max-width','none');

		    $('#regresa').on('click', function (e) {
				$.redirect(ubase+"/home",
					{  '_token': $('meta[name="csrf-token"]').attr('content') });
		    });

                 if ($('.myaccount').text()!==PERMISO.NB_DIRGRAL) {
                     $('.noadmin').remove();
                     $('.titulo').text('Administrador de Cementerio');
                 }

		 window.arma_botones = function (ren) {
			       var edicion='<div type="button" class="btn btn-pine-gree verusuario" >Ver</div>';
			       return '<td class="d-flex justify-content-between">'+
			       edicion
			       '</td>';
		};

		var disusuarios = {
						 'nombreCompletoBD' : { header : 'Nombre', 'class' : 'font-weight-bold' }
					       , 'idUsuario' : { header : 'idUsuario', 'class' : 'd-none' }
					       , 'Updated' : { header : 'Fecha de registro', 'class' : 'font-weight-bold' }
					       , 'descripcionRol' : { header : 'Tipo de Usuario', 'class' : 'font-weight-bold' }
					       , 'nb_municipio' : { header : 'Demarcación Territorial', 'class' : 'font-weight-bold' }
					       , 'nb_cementerio' : { header : 'Cementerio', 'class' : 'font-weight-bold' }
					       , 'userRegistro' : { header : 'Persona que registra', 'class' : 'font-weight-bold' }
					       , 'ver' : { header : 'Acciones', 'armacol' : 'arma_botones(tr)' }
				 };


		 window.ver_usuario = function (e) {
				var idUsuario = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
				$.redirect(ubase+"/usuariover",
					{ idUsuario: idUsuario, '_token': $('meta[name="csrf-token"]').attr('content') });
		 }

        //  TODO: Se comentó - Fran
        //  var _us = _usuarios;
        //  armadatagrid(_us,disusuarios,'dg_usuarios',true);

        $('.verusuario').on('click', function (e) {
            e.preventDefault();
            ver_usuario(e)
        });

        $('#responsable').on('click', function (e) {
            e.preventDefault();
            $.redirect(ubase + "/registrarUsuario", {
                id_permiso: PERMISO.RESPONSABLE,
                permiso_des: PERMISO.RESPONSABLEDES,
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });

        $('#administrador').on('click', function (e) {
            e.preventDefault();
            $.redirect(ubase + "/registrarUsuario", {
                id_permiso: PERMISO.ADMIN,
                permiso_des: PERMISO.ADMINDES,
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });


		 $('#filtrar').on('click', function (e) {
		      e.preventDefault();
		      filtrar(e)
		 });

		 $('#limpiar').on('click', function (e) {
		      e.preventDefault();
			  armadatagrid(_us,disusuarios,'dg_usuarios',true);
		      $('#frmbandejausuario')[0].reset();
		 });

		 window.filtrar = function() {
					  var Data1 = {};
					  var method='post';

					  if ($('#nombre').val()!='') {
					      Data1['nombre'] = $('#nombre').val();
					  }
					  if ($('#segundoApellido').val()!='') {
					      Data1['segundoApellido'] = $('#segundoApellido').val();
					  }
					  if ($('#primerApellido').val()!='') {
					      Data1['primerApellido'] = $('#primerApellido').val();
					  }
					  if ($('#id_permiso option:selected').val()!='') {
					      Data1['id_permiso'] = $('#id_permiso option:selected').val();
					  }
					  if ($('#id_alcaldia option:selected').val()!='') {
					      Data1['id_alcaldia'] = $('#id_alcaldia option:selected').val();
					  }
					  if ($('#updated_at').val()!='') {
					      Data1['updated_at'] = $('#updated_at').val();
					  }

					  $.ajax({
						    dataType: 'json',
						    type: method,
						    url: ubase+'/usuariosfiltrar',
						    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
						    data: Data1,
						    success: function(data){

							 _usuarios=data.data;
							 armadatagrid(_usuarios,disusuarios,'dg_usuarios',true);
							 $('.verusuario').on('click', function (e) {
                                                              e.preventDefault();
							      ver_usuario(e)
							 });

						    },

						    error: function( jqXhr, textStatus, errorThrown ){
							  if (jqXhr.responseText.indexOf('duplicate key')!=-1) {
								  $('#yaexistelugar').modal('show');
							  }
						    }
					 });
		 }
	}
});
