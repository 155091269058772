if ($('#frm_basica_ocupacion').length) {

    if ($('#id_cementerio').data('id_espacio') != '') {
        var tit = $('.card-header').text();
        $('.card-header').text('Edicion de ' + tit)
    }

    $('#btn_registrar_espacio').on('click', function (e) {
        e.preventDefault();
        var forma = $('#frm_basica_ocupacion')[0];
        if (forma.checkValidity() === false) {
            forma.classList.add('was-validated');
            return;
        }
        if (parseInt($('#nu_ocupa_inicial').val()) > parseInt($('#nu_limite').val())) {
            $('#nu_ocupa_inicial').attr('max', $('#nu_limite').val())
            forma.classList.add('was-validated');
            var div = $('#nu_ocupa_inicial').next();
            $(div).text('La ocupación inicial no puede ser mayor al límite de ocupación');
            return;
        }
        alta_espacio();
    });

    $('input[type=text]').change(function () {
        return $(this).val(this.value.toUpperCase());
    })

    $('#regresa').on('click', function (e) {
        window.location.href = ubase + "/ocupaciones";
    });

    // $('#regresa').on('click', function (e) {
    //     var id_cementerio = $(e.target).data('id');
    //     $.redirect(ubase + "/ocupaciones", {
    //         id_cementerio: id_cementerio,
    //         '_token': $('meta[name="csrf-token"]').attr('content')
    //     });
    // });

    $('input[name="st_titulo_perpetuidad"]').on('click', function (e) {
        tienetituloperpetuidad(e);
    });

    window.tienetituloperpetuidad = function (e) {
        if (e.target.value == SINO.SI) {
            $('#tx_folio_perpetuidad').attr('required', true);
            $('#tx_folio_perpetuidad').attr('disabled', false);
        }
        if (e.target.value == SINO.NO) {
            $('#tx_folio_perpetuidad').attr('required', false);
            $('#tx_folio_perpetuidad').attr('disabled', true);
        }
    };

    $('#btn_cancelar').on('click', function (e) {
        $('#regresa').trigger('click');
    })

    window.alta_espacio = function () {
        var method = 'post';
        var forma = $('#frm_basica_ocupacion');
        var Data1 = forma.serialize();
        if ($('#id_cementerio').data('id_espacio') != '') {
            var method = 'put';
            Data1 = Data1 + '&id_espacio=' + $('#id_cementerio').data('id_espacio') + '&id_cementerio=' + $('#id_cementerio').data('id');;
        }
        $.ajax({
            dataType: 'json',
            type: method,
            url: ubase + '/cementeriosocupacionesalta',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {
                $('#registroexitoso').modal('show');
                $("#registroexitoso").on("hidden.bs.modal", function () {
                    $('#regresa').trigger('click');
                });
            },

            error: function (jqXhr, textStatus, errorThrown) {
                console.log();
                if (jqXhr.responseText.indexOf('duplicate key') != -1) {
                    $("#yaexisteocupacion").on("hidden.bs.modal", function () {
                        $('#tx_clave_ocupacion').focus();
                    });
                    $('#yaexisteocupacion').modal('show');
                }else if (jqXhr.responseText.indexOf('No hay dis') != -1) {
                    $('#nohaydisponibilidad').modal('show');
                }else{
                    __crearMensaje(true,"Error","Ocurrió un error.");
                }
            }
        });
    }
}
