$(document).ready(function () {
    if ($('#registrarIncidencia').length) {
        $('.st-input').css('max-width', 'none'); /* quita la limitante del max-width y que se controle x bootstrap */

        $('#regresa').on('click', function (e) {
            $.redirect(ubase + "/incidencias", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });

        $('#id_cementerio').on('change', function (e) {
            if (($('#id_cementerio').val() !== '' && $('#id_cementerio').val() !== null)) {
                buscaCementerioArea();
            }
        });

        $('#id_tipo_incidencia').on('change', function (e) {
            $('.error_tipoIncidenciaDuplicada').addClass('d-none');
        });

        $('#tx_observaciones').on('change', function (e) {
            $('.observacionobli').addClass('d-none');
        });

        $('#fh_visita').on('change', function (e) {
            $('.errorfechavisita').addClass('d-none');
        });

        $('#agregaseguimiento').on('click', function (e) {
            if ($('#tx_observaciones').val() == '') {
                $('#tx_observaciones').focus();
                $('.observacionobli').removeClass('d-none');
            }
            if ($('#fh_visita').val() == '') {
                $('#fh_visita').focus();
                $('.errorfechavisita').removeClass('d-none');
            }
            if ($('#tx_observaciones').val() == '' || $('#fh_visita').val() == '') {
                return false;
            }

            $('.observacionobli').addClass('d-none');
            $('.errorfechavisita').addClass('d-none');
            alta_seguimiento(this);
        });

        window.MuestraEscondeSeguimiento = function (e) {
            if ($(e.parentNode.nextElementSibling).hasClass('d-none')) {
                $(e.parentNode.nextElementSibling).removeClass('d-none');
                $(e).addClass('fa-minus-circle');
                $(e).removeClass('fa-plus-circle');
            } else {
                $(e.parentNode.nextElementSibling).addClass('d-none');
                $(e).removeClass('fa-minus-circle');
                $(e).addClass('fa-plus-circle');
            }
        }

        window.alta_seguimiento = function (e) {

            var Data1 = {};
            Data1['tx_observaciones'] = $('#tx_observaciones').val();
            Data1['fh_visita'] = $('#fh_visita').val();
            Data1['id_incidencia'] = $('#frm_registraincidencia').data('id_incidencia');
            $.ajax({
                dataType: 'json',
                type: 'post',
                url: ubase + '/incidenciaAltaSeguimiento',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    muestra_seguimiento(data);
                    $('#tx_observaciones').val('');
                    $('#fh_visita').val('');
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    if (jqXHR.status === 401) {
                        __crearMensaje(true, "Error", "Tu sesión ha caducado");
                    }
                    if (jqXHR.status === 418) {
                        __crearMensaje(true, "Error", data.mensaje || data.message);
                    }
                    $('#modal-logo').modal({
                        backdrop: 'static',
                        keyboard: false
                    });

                }
            });
        }




        window.buscaCementerioArea = function () {
            $('.error_tipoIncidencia').addClass('d-none');
            var Data1 = {};
            var method = 'post';
            if ($('#id_cementerio').val() != '') {
                Data1['id_cementerio'] = $('#id_cementerio').val();
            }
            $.ajax({
                dataType: 'json',
                type: method,
                url: ubase + '/buscarAreasCementerio',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    armaOpcionesCementerioArea(data[0].areas)
                    if (typeof _incidencia !== 'undefined') {
                        $('#id_area option[value="' + _incidencia.id_area + '"]').prop('selected', true)
                    }
                },

                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseText.indexOf('duplicate key') != -1) {
                        $('#yaexistelugar').modal('show');
                    }
                    if (jqXHR.status === 401) {
                        __crearMensaje(true, "Error", "Tu sesión ha caducado");
                    }
                    if (jqXHR.status === 418) {
                        __crearMensaje(true, "Error", data.mensaje || data.message);
                    }
                    $('#modal-logo').modal({
                        backdrop: 'static',
                        keyboard: false
                    });

                }
            });
        }


        $('#agregatipoincidencia').on('click', function (e) {
            var valor = $('#id_tipo_incidencia').next().next().find('input').val()
            if (valor == '') {
                $('.error_tipoIncidenciaDuplicada').removeClass('d-none');
                return false;
            }
            alta_tipoincidencia(valor);
        });


        window.alta_tipoincidencia = function (valor) {
            $('.error_tipoIncidenciaDuplicada').addClass('d-none');
            var Data1 = {};
            Data1 = '?&tp_tipo_incidencia=' + valor.toUpperCase();
            $.ajax({
                dataType: 'json',
                type: 'post',
                url: ubase + '/registrarTipoIncidencia',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    muestra_tipo_incidencia(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseText.indexOf('duplicate key') != -1) {
                        $('.error_tipoIncidenciaDuplicada').removeClass('d-none');
                    }
                    if (jqXHR.status === 401) {
                        __crearMensaje(true, "Error", "Tu sesión ha caducado");
                    }
                    if (jqXHR.status === 418) {
                        __crearMensaje(true, "Error", data.mensaje || data.message);
                    }
                    $('#modal-logo').modal({
                        backdrop: 'static',
                        keyboard: false
                    });

                }
            });
        }



        $("#id_cementerio").on("changed.bs.select",
            function (e, clickedIndex, newValue, oldValue) {
                $('.s_cementerioobli').addClass('d-none');
            });


        window.armaOpcionesCementerioArea = function (opciones) {
            $('#id_area').children().remove();
            $('#id_area').append('<option value="' + '" >Elige una área</option>');
            $.each(opciones, function (e) {
                $('#id_area').append('<option value="' + opciones[e].id + '" >' + opciones[e].tx_area + '</option>');
            });
        }

        window.upd_dato = function () {
            var Data1 = {};
            var method = 'post';
            var url = '/creaIncidencia';
            var forma = $('#frm_registraincidencia');
            var Data1 = forma.serialize();
            if ($('#frm_registraincidencia').data('id_incidencia') != '') {
                var method = 'put';
                var url = '/updateIncidencia';
                Data1 = Data1 + '&id=' + $('#frm_registraincidencia').data('id_incidencia');
            }

            if ($('.visualizar').length > 0) {
                Data1 = Data1 + '&id_archivo_plan_accion=' + $('.visualizar').data('id');
            }

            $.ajax({
                dataType: 'json',
                type: method,
                url: ubase + url,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    $('#registroexitoso').modal('show');
                    $("#registroexitoso").on("hidden.bs.modal", function () {
                        $.redirect(ubase + "/incidencias", {
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        });
                    })
                },

                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status === 401) {
                        __crearMensaje(true, "Error", "Tu sesión ha caducado");
                    }
                    if (jqXHR.status === 418) {
                        __crearMensaje(true, "Error", data.mensaje || data.message);
                    }
                    $('#modal-logo').modal({
                        backdrop: 'static',
                        keyboard: false
                    });

                }
            });
        }

        $('#fh_incidencia, #hm_incidencia').on('click', function (e) {
            $('.errorfechamayor').addClass('d-none');
        });

        $('#estatus').on('click', function (e) {
            e.preventDefault();
            var forma = $('#frm_registraincidencia')[0];
            var fechahora = $('#fh_incidencia').val();
            var fechaactual = $('#hm_incidencia').data('max');
            var fechavalida = fechahora < fechaactual ? true : false;
            if (forma.checkValidity() === false ||
                $('input[type="file"].required:not([data-archivo])').length > 0 ||
                ($('#id_cementerio').val() === '' || $('#id__cementerio').val() === null) ||
                ($('#id_tipo_incidencia').val() === '' || $('#id_tipo_incidencia').val() === null) ||
                fechavalida == false
            ) {
                forma.classList.add('was-validated');

                if ($('input[type="file"].required:not([data-archivo])').length > 0) {
                    $('input[type="file"].required:not([data-archivo])').parent().next().removeClass('d-none');
                }

                if ($('#id_cementerio').val() === '' || $('#id_cementerio').val() === null) {
                    $('.s_cementerioobli').removeClass('d-none');
                }

                if ($('#id_tipo_incidencia').val() === '' || $('#id_tipo_incidencia').val() === null) {
                    $('.s_incidenciaobli').removeClass('d-none');
                }
                if (fechavalida == false) {
                    $('.errorfechamayor').removeClass('d-none');
                }

                return;
            }
            upd_dato(e);

        })


        var options = {
            ajax: {
                url: ubase + '/cementeriosbuscar',
                type: 'POST',
                dataType: 'json',
                data: function () { // This is a function that is run on every request
                    return {
                        q: '{{{q}}}'
                    }
                }
            },
            minLength: 4,
            liveSearch: true,
            locale: {
                emptyTitle: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                errorText: 'No se encontraron cementerios',
                statusSearching: 'Buscando ....',
                searchPlaceholder: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusInitialized: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusTooShort: 'Por favor Tecleé más dígitos',
                currentlySelected: 'Actualmente seleccionado',
                statusNoResults: 'No se encontraron cementerios'
            },
            log: 'error',
            preprocessData: function (data) {
                var i, l = data.length,
                    array = [];
                if (l) {
                    for (i = 0; i < l; i++) {
                        array.push($.extend(true, data[i], {
                            text: data[i].nb_cementerio + ',' + data[i].nb_municipio + ',' + data[i].nb_clasificacion,
                            value: data[i].id,
                            data: {
                                // subtext: data[i].estado
                            }
                        }));
                    }
                }
                return array;
            }
        };

        var optionsTI = {
            ajax: {
                url: ubase + '/tipoincidenciabuscar',
                type: 'POST',
                dataType: 'json',
                data: function () { // This is a function that is run on every request
                    return {
                        q: '{{{q}}}'
                    }
                }
            },
            minLength: 4,
            liveSearch: true,
            locale: {
                emptyTitle: 'Tecleé el tipo de incidente',
                errorText: 'No se encontro un tipo de incidente',
                statusSearching: 'Buscando ....',
                searchPlaceholder: 'Tecleé el tipo de incidente',
                statusInitialized: 'Tecleé el tipo de incidente',
                statusTooShort: 'Por favor Tecleé más dígitos',
                currentlySelected: 'Actualmente seleccionado',
                statusNoResults: 'No se encontro un tipo de incidente'
            },
            log: 'error',
            preprocessData: function (data) {
                var i, l = data.length,
                    array = [];
                if (l) {
                    for (i = 0; i < l; i++) {
                        array.push($.extend(true, data[i], {
                            text: data[i].tp_tipo_incidencia,
                            value: data[i].id,
                            data: {
                                // subtext: data[i].estado
                            }
                        }));
                    }
                }
                return array;
            }
        };




        $(function () {
            $('#id_cementerio').selectpicker({
                liveSearch: true,
                styleBase: 'form-control form-control-cdmx'
            }).filter('.with-ajax').ajaxSelectPicker(options);
        });
        $(function () {
            $('#id_tipo_incidencia').selectpicker({
                liveSearch: true,
                styleBase: 'form-control form-control-cdmx'
            }).filter('.with-ajax').ajaxSelectPicker(optionsTI);
        });



        $('.fa-caret-square-down').on('click', function (e) {
            var anterior = $(this).prev();
            $(anterior).attr('size', $(anterior).children('option').length)
        });



        window.muestra_incidencia = function (data) {
            $('input[id^="id_file_"]').next().next().remove(); /*remueve el boton de visualizar */
            $('input[id^="id_file_"]').removeAttr('data-archivo');; /*remueve el boton de visualizar */
            $('#id_tipo_incidencia option[value=""]').prop('selected', true)

            $('#fh_incidencia').val(data.fh_incidencia);
            $('#hm_incidencia').val(data.hm_incidencia);
            $('#tx_lugar').val(data.tx_lugar);
            $('#tx_descripcion').val(data.tx_descripcion);
            $('#tx_acciones').val(data.tx_acciones);
            muestra_picker(data.cementerio);
            if (data.archivo_accion != null && "tx_uuid" in data.archivo_accion) {
                var tx_uuid = data.archivo_accion.tx_uuid;
                crearBotonDescarga(tx_uuid, $($('#id_file_0018')[0].parentNode), data.archivo_accion.id);
            }
            muestra_tipo_incidencia(data.tipo_incidencia);
            buscaCementerioArea();
            $('#div_seguimientos').removeClass('d-none');
            muestra_seguimientos(data.seguimientos);
        }

        window.muestra_seguimientos = function (data) {
            $.each(data, function (e) {
                muestra_seguimiento(data[e]);
            });
        }

        window.muestra_seguimiento = function (data) {
            var areanum = $('.btn-menos1').length + 1;
            var areavalue = data.tx_observaciones;
            var areahtml = '' +
                '<div class="row col-lg-12 mb-3">' +
                '<div class="col-lg-12 form-group d-flex align-items-center ideofi mb-1" id="div_' + data.id + '">' +
                '<label for="horizontal" class="control-label form-label-cdmx col-lg-11 pl-0 texto-verde">Seguimiento ' + areanum + '</label>' +
                '<i  id="btn-menos-' + data.id + '" data-id="' + data.id +
                '" class="btn-menos1 fa fa-minus-circle d-flex col-lg-1 justify-content-end align-items-center"></i>' +
                '</div>' +
                '<div class="col-lg-12 flex-wrap ideofi pl-0" >' +
                '<div class="col-lg-12">' + data.fhVisitaLarga + '</div>' +
                '<textarea class="textoseguimiento form-control form-control-custom col-lg-12" rows=5 type="text" name="area"' +
                'id="area_' + areanum + '" readonly>' + areavalue + '</textarea>' +
                '</div>' +
                '</div>';
            $('.seguimientos').append(areahtml);
            $('#btn-menos-' + data.id).on('click', function (e) {
                MuestraEscondeSeguimiento(this);
            });
        }


        window.muestra_tipo_incidencia = function (data) {
            $('#id_tipo_incidencia option[value="' + data.id + '"]').prop('selected', true)
            $('#id_tipo_incidencia').append('<option value="' + data.id + '" selected="selected">' +
                data.tp_tipo_incidencia + '</option>').selectpicker('refresh');
            var text = $("#id_tipo_incidencia option[value='" + data.id + "']").text();
            $($('#id_tipo_incidencia')[0].parentNode).find('.filter-option-inner-inner').text(text);
        }

        window.muestra_picker = function (data) {
            if (data != null) {
                if ($("#id_cementerio option[value='" + data.id + "']").length == 0) {
                    $('#id_cementerio').append('<option value="' + data.id + '" selected="selected">' +
                        data.nb_cementerio + ', ' + data.alcaldia.nb_municipio + ', ' + data.clasificacion.nb_clasificacion + '</option>').selectpicker('refresh');
                }
                var text = $("#id_cementerio option[value='" + data.id + "']").text();
                $($('#id_cementerio')[0].parentNode).find('.filter-option-inner-inner').text(text);
            } else {
                $('#id_cementerio').find('option').remove();
                $($('#id_cementerio')[0].parentNode).find('.filter-option-inner-inner').text('')
                $('.dropdown-item.active.selected').children().remove();
            }
        }

        window.alta_usuario = function () {
            $('.seluser').prop('disabled', false);
            $('.seluser').removeClass('disabled');
            $('#tx_folio_identificacion').focus();
        }

        if ($('#idIncidencia').prop('disabled')) {
            /* se va a editar un usuario */
            $('#estatus').text('Guardar');
            $('#idIncidencia :nth-child(2)').prop('selected', true).trigger('change');
        }

        $('#btn_cancelar').on('click', function (e) {
            $('#regresa').trigger('click');
        })

        if (typeof _incidencia !== 'undefined') {
            muestra_incidencia(_incidencia);
            $('#estatus').text('Guardar');
            $('.desrol').text('Editar Incidencia');
            $('#frm_registraincidencia').data('id_incidencia', _incidencia.id);
        }

    }

});
