window.crearBotonDescarga = function (filesystem,quediv,id=0) {
/*
                              if (quediv.hasClass('col-md-8')) {
                                 quediv.next()[0].getElementsByTagName('a')[0].href=mipath()+'storage/'+filesystem;
                              } else {
*/
	              //$('#visualizar').remove();
                      quediv.find('.visualizar').remove();
		      if (filesystem!=null) {
			      his=filesystem.split('.');
			      quediv[0].getElementsByTagName('input')[0].innerHTML=filesystem;
			      quediv[0].getElementsByTagName('input')[0].setAttribute('data-archivo',filesystem)
			      quediv.addClass('col-md-8');
			      div = document.createElement('div');
			      div.setAttribute('class','col-md-4 d-flex visualizar');
			      //div.setAttribute('id','visualizar');
			      div.setAttribute('data-id',id);
			      div.setAttribute('data-uuid',filesystem);
			      a=document.createElement('a');
			      a.setAttribute('href',ubase+'/storage/'+filesystem);
			      a.setAttribute('target','_blank');
			      a.setAttribute('class','btn btn-pine-gree');
			      tdCelda = document.createTextNode('Visualizar')
			      a.appendChild(tdCelda);
			      div.appendChild(a);
			      p=document.createElement('p');
			      p.setAttribute('class','hisdocumentos '+his[0] );
			      p.setAttribute('data-toggle','tooltip');
			      p.setAttribute('data-title','Consulta el historial de documentos');
			      i=document.createElement('i');
			      i.setAttribute('class','d-none fas fa-history ml-2 fa-2x');
			      p.appendChild(i);
			      div.appendChild(p);
			      //quediv[0].parentNode.appendChild(div);
			      quediv[0].appendChild(div);
			      $('[data-toggle="tooltip"]').tooltip( {
				 template: '<div class="tooltip tooltip-info"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>' });
			      $('.'+his[0]).on('click',function(e){
				      e.preventDefault();
				      muestra_documentos(filesystem,id);
			       });
		       }
//                             }

                      quediv.find('.visualizar').on('click', function (e) {
		              _visualizar(e);
	              });
}

