document.addEventListener('DOMContentLoaded', () => {
    const _listFiles = ['plan_de_accion_para_evitar_reincidencia']
    const b_guardar = ['tx_tipo_incidencia', 'fh_incidencia', 'hm_incidencia', 'tx_lugar', 'tx_descripcion', 'tx_acciones'];
    const b_add = ['fh_visita', 'tx_observaciones'];
    _listFiles.forEach((e) => {
        var _file = document.querySelector('input[name="archivo_'+e+'"]');
        if(_file){
            _file.addEventListener('change', (el) => {
                el.target.parentElement.classList.remove('border', 'border-danger');
                _uploadSizeDb = el.target.dataset.uploadsize;
                _uploadTypeDb = el.target.dataset.uploadtype;

                var _fileName = el.target.files[0].name;
                var _fileSize = el.target.files[0].size;

                kb= _fileSize / 1024;
                __mfs = _uploadSizeDb * 1024

                if(!el.target.files[0].type.includes(_uploadTypeDb)){
                    el.target.files = new DataTransfer().files;
                    el.target.value = null;
                    alert( _fileName + ', este archivo no será cargado porque no corresponde a una extensión valida.');
                } else if(kb > __mfs){
                    el.target.files = new DataTransfer().files;
                    el.target.value = null;
                    alert( _fileName + ', este archivo no será cargado porque excede el tamaño permitido de '+ _uploadSizeDb +' megas.');
                } else {
                    _file.nextElementSibling.innerHTML = _fileName;
                    _file.nextElementSibling.style.width = '195px';
                    _file.nextElementSibling.style.wordWrap = 'break-word';
                    let buttonPreview = _file.parentElement.children['buttonPreview'];
                    if(buttonPreview.getElementsByTagName('a')[0] == null){
                        buttonPreview.classList.remove('d-none');
                        ahrefPreview=document.createElement('a');
                        blobUrl = URL.createObjectURL(el.target.files[0]);
                        ahrefPreview.innerText = 'Visualizar';
                        ahrefPreview.classList.add('d-inline', 'st-btn', 'green')
                        ahrefPreview.setAttribute('href', blobUrl);
                        ahrefPreview.setAttribute('target', '_blank')
                        ahrefPreview.setAttribute('download', _fileName);
                        buttonPreview.appendChild(ahrefPreview);
                        let buttonDelete = _file.parentElement.children['buttonDelete'];
                        buttonDelete.classList.remove('d-none');
                    }
                }
            });
        }
    });

    window.deleteFileIncidencia = function (e) {
        e.parentElement.classList.add('d-none');
        e.parentElement.parentElement.children['buttonPreview'].classList.add('d-none');
        e.parentElement.parentElement.children['buttonPreview'].innerHTML ='';
        e.parentElement.parentElement.querySelector('p.texto').innerHTML = '<i class="fa fa-cloud-upload-alt mr-1"></i>Carga de archivos';
        e.parentElement.parentElement.querySelector('p.texto').style.width = 'auto';
        e.parentElement.parentElement.querySelector('p.texto').style.wordWrap = 'initial';
        e.parentElement.parentElement.querySelector('input[type="file"]').value = null;
        e.parentElement.parentElement.querySelector('input[type="file"]').files = new DataTransfer().files;
    }

    $('#confirm-form-incidencia').on('hidden.bs.modal', function () {
        var forms = document.getElementsByClassName('form-validation-incidencia');
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.classList.remove('was-validated');
        });
        let buttonFormGuardar = document.getElementById('buttonFormGuardar');
        if(buttonFormGuardar){
            buttonFormGuardar.remove()
        }
        let buttonFormSeguimiento = document.getElementById('buttonFormSeguimiento');
        if(buttonFormSeguimiento){
            buttonFormSeguimiento.remove();
        }
        b_add.forEach((e) => {
            if(e){
                document.getElementById(e).classList.remove('is-invalid');
                document.getElementById(e).classList.remove('is-valid');
                document.getElementById(e).readOnly = false;
                document.getElementById(e).required = true;
                document.getElementById(e).value = null;
            }
        });
        b_guardar.forEach((e) => {
            if(e){
                document.getElementById(e).classList.remove('is-invalid');
                document.getElementById(e).classList.remove('is-valid');
                document.getElementById(e).readOnly = false;
                document.getElementById(e).required = true;
            }
        });
    });

    window.sendDataSeguimiento = function(_button){
        var forms = document.getElementsByClassName('form-validation-incidencia');
        _button.type = 'submit';
        if(_button && _button.value == 'Guardar'){
            let buttonFormSeguimiento = document.getElementById('buttonFormSeguimiento');
            if(buttonFormSeguimiento){
                buttonFormSeguimiento.remove();
            }
            let buttonFormGuardar = document.getElementById('buttonFormGuardar');
            if(!buttonFormGuardar){
                let hid_val = document.createElement('input');
                hid_val.type = 'hidden';
                hid_val.name = 'save_form';
                hid_val.value = 'save_form';
                hid_val.id = 'buttonFormGuardar';
                _button.appendChild(hid_val);
            }
            b_guardar.forEach((e) => {
                if(e){
                    document.getElementById(e).required = true;
                    document.getElementById(e).readOnly = false;
                }
            });
            b_add.forEach((e) => {
                if(e){
                    document.getElementById(e).required = false;
                    document.getElementById(e).readOnly = true;
                    document.getElementById(e).value = null;
                }
            });
        }
        if(_button && _button.name == 'button_seguimiento'){
            let buttonFormGuardar = document.getElementById('buttonFormGuardar');
            if(buttonFormGuardar){
                buttonFormGuardar.remove()
            }
            let buttonFormSeguimiento = document.getElementById('buttonFormSeguimiento');
            if(!buttonFormSeguimiento){
                let hid_val = document.createElement('input');
                hid_val.type = 'hidden';
                hid_val.name = 'add_seguimiento';
                hid_val.value = 'add_seguimiento';
                hid_val.id = 'buttonFormSeguimiento';
                _button.appendChild(hid_val);
            }
            b_add.forEach((e) => {
                if(e){
                    document.getElementById(e).required = true;
                    document.getElementById(e).readOnly = false;
                }
            });
            b_guardar.forEach((e) => {
                if(e){
                    document.getElementById(e).required = false;
                    document.getElementById(e).readOnly = true;
                }
            });
        }
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                if (form.checkValidity() === true) {
                    if(_button.value == 'Registrar'){
                        form.submit();
                    } else {
                        _button.type = 'button';
                        $('#confirm-form-incidencia').modal('show');
                        return false;
                    }
                }else{
                    _button.type = 'button';
                    __crearMensaje(true,"Error","Por favor revisa los campos marcados con rojo.");
                    form.querySelectorAll('input').forEach((elem) => {
                        if(elem.type == 'file' && elem.required){
                            if(!elem.checkValidity()){
                                elem.parentElement.classList.add('border', 'border-danger');
                            }
                        } else if (elem.type == 'text' && elem.required){
                            if(!elem.checkValidity()){
                                elem.classList.add('is-invalid');
                            } else {
                                elem.classList.remove('is-invalid');
                                elem.classList.add('is-valid');
                            }
                        } else if (elem.type == 'date' && elem.required){
                            if(!elem.checkValidity()){
                                elem.classList.add('is-invalid');
                            } else {
                                elem.classList.remove('is-invalid');
                                elem.classList.add('is-valid');
                            }
                        }
                    });
                    form.querySelectorAll('select').forEach((elem) => {
                        if(!elem.checkValidity()){
                            elem.classList.add('is-invalid');
                        } else {
                            elem.classList.remove('is-invalid');
                            elem.classList.add('is-valid');
                        }
                    });
                }
                form.classList.add('was-validated');
            }, false);
        });
    }

    window.sendFormDataIncidencia = function(e){
        e.disabled = true;
        document.getElementById('formIncidencia').submit();
    }

    window.showModalFormIncidencia = function(e){
        $('#registro-exitoso-incidencia').modal('show');
        $("#registro-exitoso-incidencia").on("hidden.bs.modal", function () {
            $.redirect(ubase + "/incidencia-prop", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });
    }

    window.showModalBorradoIncidenciaSeguimiento = function(e){
        $('#borrado-exitoso-incidencia-seguimiento').modal('show');
        $("#borrado-exitoso-incidencia-seguimiento").on("hidden.bs.modal", function () {
            $.redirect(ubase + "/incidencia-prop", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });
    }

});