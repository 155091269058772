$(document).ready(function() {
    if ($('#prestadoresbandeja').length) {
        window.switchPrestadores = function (e){
            if(e.checked == true){
                ajaxPrestadores(e.checked, e.value);
            } else if (e.checked == false){
                ajaxPrestadores(e.checked, e.value);
            }
        }
    }

    function ajaxPrestadores(val, _id){
        $.ajax({
            url: ubase+'/prestador_estatus',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({check: val, id: _id}),
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            type: 'POST',
            async: true,
            success: function(data) {
                $('#registroexitoso').modal('show');
                // console.log(data.data);
                document.getElementById('registroexitoso').firstElementChild.firstElementChild.firstElementChild.innerHTML = data.data;
            },
            error: function(jqXHR, textStatus, errorThrown) {
                switch(jqXHR.status){
                    case 419:{
                        err = 'Tu sesión ha caducado, por favor inicia sesión de nuevo';
                        break;
                    }
                    default:{
                        err = jqXHR.responseJSON.mensaje || jqXHR.responseJSON.message;
                        crearMensaje(true,"Error: ", err).then(function() {});
                        break;
                    }
                }
                // console.log(err);
                // $('#btn-loader-pics-f-'+idFile).hide();
                // $('#btn-view-pics-f-'+idFile).show();
            }
        });
    }
});
