$(document).ready(function () {

    // Formulario: Cementerio
    if ($('#nuevoCementerio').length) {

        var rol = $('#user-rol').val();
        var is_writable = $('#is-writable').val();

        if (!is_writable) {
            switch (rol) {
                case 'is_dirGral':
                    break;

                case 'is_responsable':
                    $('.fields input, .fields select').attr('disabled', true);
                    $('#frm_lugarfisico #nu_ocupa_inicial, #frm_lugarfisico #nu_limite').attr('disabled', false);
                    // $('.cont-actions .verlugar').remove();
                    break;

                case 'is_admin':
                    break;
            }
        }
    }

    // Formulario: Ocupación
    if ($('#ocupacion').length) {

        var rol = $('#user-rol').val();
        var is_writable = $('#is-writable').val();

        if (!is_writable) {
            switch (rol) {
                case 'is_dirGral':
                    break;

                case 'is_responsable':
                    $('.fields input, .fields select').attr('disabled', true);
                    break;

                case 'is_admin':
                    break;
            }
        }
    }
});
