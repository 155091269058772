if ($('.menu_m').length) {
                    $('.action a[title="Bandeja de ocupaciones"]').on('click', function (e) {
                                var url=e.target.href;
                                e.preventDefault();
                                $.redirect(url,
                                        {  '_token': $('meta[name="csrf-token"]').attr('content') });
                    });

}

