$(document).ready(function() {
	if ($('#recuperacionesbandeja').length) {

		 $('.st-input').css('max-width','none');

		 window.arma_botones = function (ren) {
			       var edicion='<div type="button" class="btn btn-pine-gree verrecuperacion" >Ver</div>';
			       return '<td class="d-flex justify-content-between">'+
			       edicion
			       '</td>';
		};

		var disrecuperaciones = {
					        'nb_cementerio' : { header : 'Cementerio', 'class' : 'font-weight-bold' }
					       , 'id' : { header : 'idUsuario', 'class' : 'd-none' }
					       , 'updatedBD' : { header : 'Fecha de registro', 'class' : 'font-weight-bold' }
					       , 'tp_espacio' : { header : 'Lugar Recuperado', 'class' : 'font-weight-bold' }
					       , 'tx_clave_ocupacion' : { header : 'Clave del Lugar Recuperado', 'class' : 'font-weight-bold' }
					       , 'nb_municipio' : { header : 'Demarcación Territorial', 'class' : 'font-weight-bold' }
					       , 'user_registro' : { header : 'Persona que registra', 'class' : 'font-weight-bold' }
					       , 'ver' : { header : 'Acciones', 'armacol' : 'arma_botones(tr)' }
				 };


		 window.ver_recuperacion = function (e) {
				var idPrestador = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
				$.redirect(ubase+"/recuperacionver",
					{ idPrestador: idPrestador, '_token': $('meta[name="csrf-token"]').attr('content') });
		 }

		 armadatagrid(_recuperaciones,disrecuperaciones,'dg_recuperaciones',true);

		 $('.verrecuperacion').on('click', function (e) {
                      e.preventDefault();
		      ver_recuperacion(e)
		 });
 
		 $('#registrar').on('click', function (e) {
                                e.preventDefault();
                                $.redirect(ubase+"/registrarRecuperacion",
                                        { '_token': $('meta[name="csrf-token"]').attr('content') });
		 });



		 $('#filtrar').on('click', function (e) {
		      e.preventDefault();
		      filtrar(e)
		 });

		 $('#limpiar').on('click', function (e) {
		      e.preventDefault();
		      $('#frmbandejarecuperaciones')[0].reset();
		 });

		 window.filtrar = function() {
					  var Data1 = {};
					  var method='post';
                                          $('#filtrar').prop('disabled',true);
					  if ($('#tx_clave_ocupacion').val()!='') {
					      Data1['tx_clave_ocupacion'] = $('#tx_clave_ocupacion').val();
					  }

					  if ($('#id_alcaldia option:selected').val()!='') {
					      Data1['id_alcaldia'] = $('#id_alcaldia option:selected').val();
					  }

					  if ($('#id_tipo_espacio option:selected').val()!='') {
					      Data1['id_tipo_espacio'] = $('#id_tipo_espacio option:selected').val();
                                          }

					  if ($('#id_cementerio option:selected').val()!='') {
					      Data1['id_cementerio'] = $('#id_cementerio option:selected').val();
					  }

					  if ($('#updated_at').val()!='') {
					      Data1['updated_at'] = $('#updated_at').val();
					  }

					  $.ajax({
						    dataType: 'json',
						    type: method,
						    url: ubase+'/recuperacionesfiltrar',
						    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
						    data: Data1,
						    success: function(data){
                                                         $('#filtrar').prop('disabled',false);
							 _recuperaciones=data.data;
							 armadatagrid(_recuperaciones,disrecuperaciones,'dg_recuperaciones',true);
							 $('.verrecuperacion').on('click', function (e) {
                                                              e.preventDefault();
							      ver_recuperacion(e)
							 });

						    },

						    error: function( jqXhr, textStatus, errorThrown ){
                                                          $('#filtrar').prop('disabled',false);
							  if (jqXhr.responseText.indexOf('duplicate key')!=-1) {
								  $('#yaexistelugar').modal('show');
							  }
						    }
					 });
		 }
	}
});
