
jQuery(function(){

    /**
     * PAra ver si viene del index (bandeja) o del boton registrar
     */
    if($('#agenciaMarker').length > 0){
        const queryParams = new URLSearchParams(window.location.search);
        const a = parseInt(queryParams.get('a'));
        $('#nb_agencia').val(a);
        setTimeout(function(){
            $('#btnSearchAgencia').trigger('click');
            $('#btnSearchAgencia').remove();
            $('#nb_agencia').attr('readonly', true);
            $('#nb_agencia').selectpicker('refresh');
        },250);
        ;
    }



    /**
     * Archivos serv funerario
     */
    $('body').on('change', 'input.anybris[type="file"]', function(e) {
    //$('input.anybris[type="file"]').on("change", function(){

        const leId = this.id;
        if(this.files.length >0 ){
            const file = this.files[0];
            const reader = new FileReader();
            const fileName = file.name;
            reader.readAsDataURL(file);
            reader.onload = function () {
                b64 = reader.result;
                b64 = b64.replace('data:application/pdf;base64,','');
                bloob = b64toBlob(b64, file.type);
                $('#a-view-'+leId).attr('href',URL.createObjectURL(bloob));
                $('#filename-'+leId).html(''+ fileName);
            };
            reader.onerror = function (error) {
                alert('Ocurrió un  error al leer el archivo ' + fileName);
            };

            $('#nofilelabel-'+leId).addClass('d-none');
            $('#buttonz-'+leId).removeClass('d-none');

        }else{
            $('#nofilelabel-'+leId).removeClass('d-none');
            $('#buttonz-'+leId).addClass('d-none');
            $('#a-view-'+leId).attr('src','#');
            $('#'+leId).attr('required',true);
        }

    });

    /**
     * Buscador de las agencias (autocompletar)
     */
    $('#nb_agencia').selectpicker();



    /**
     * Boton de busar agencia
     */
    $('#btnSearchAgencia').on('click', function(){
        const baz = $('#nb_agencia').val();
        if ($('#nb_agencia').val() != '' ) {
            $.ajax({
                url: uConsultaAgencia + '/' + baz,
                dataType: 'json',
                type: 'GET',
                contentType: 'application/json',

                success: function (data) {
                    $('#tx_calle').val(data.tx_calle);
                    $('#tx_no_exterior').val(data.tx_no_exterior);
                    $('#tx_no_interior').val(data.tx_no_interior);

                    $('#tx_codigo_postal').val(data.tx_codigo_postal);
                    $('#id_municipio').val(data.id_municipio);
                    $('#id_colonia').val(data.id_colonia);
                    $('#tx_siapem').val(data.tx_folio_siapem);
                    $('#tx_rfc').val(data.tx_rfc);
                    $('#tx_folio_identificacion').val(data.tx_folio_identificacion);
                    $('#nb_responsable').val(data.nb_responsable);
                    $('#tx_apellido_pat').val(data.tx_apellido_paterno);
                    $('#tx_apellido_mat').val(data.tx_apellido_materno);
                    $('#tx_motivo_cierre').val(data.tx_motivo_cierre);
                    $('#fh_cierre_operacion').val(data.fh_cierre);
                    $('#fh_constitucion').val(data.fh_constitucion);

                    //$('#id_tipo_identificacion[value='+data.id_tipo_identificacion+']').prop('selected', true);
                    $('#id_tipo_identificacion').val(data.id_tipo_identificacion);

                    if(data.id_archivo_acta_constitutiva !=''){
                        $('#a-view-biActa').attr('href',uViewFile + '/' + data.id_archivo_acta_constitutiva);
                        $('#filename-biActa').html(data.nb_archivo_acta_constitutiva);
                        $('#nofilelabel-biActa').addClass('d-none');
                        $('#buttonz-biActa').removeClass('d-none');
                        $('#biActa').attr('required', false);
                    }

                    if(data.id_archivo_identificacion !=''){
                        $('#a-view-biIdentificacion').attr('href',uViewFile + '/' + data.id_archivo_identificacion);
                        $('#filename-biIdentificacion').html(data.nb_archivo_identificacion);
                        $('#nofilelabel-biIdentificacion').addClass('d-none');
                        $('#buttonz-biIdentificacion').removeClass('d-none');
                        $('#biIdentificacion').attr('required', false);
                    }

                    if(data.id_archivo_cierre !=''){
                        $('#a-view-biCierreOperacion').attr('href',uViewFile + '/' + data.id_archivo_cierre);
                        $('#filename-biCierreOperacion').html(data.nb_archivo_identificacion);
                        $('#nofilelabel-biCierreOperacion').addClass('d-none');
                        $('#buttonz-biCierreOperacion').removeClass('d-none');
                        $('#biCierreOperacion').attr('required', false);
                    }

                    $('#zend').attr('disabled', false);
                    // Llenar servicios
                    $('#otros-container').html('');
                    $('#otros-container').addClass('d-none') 
                    servicez = data.servicios;
                    for(s=0; s<servicez.length;s++){
                        $('#chk-srv-' + servicez[s].id).prop('checked', true);
                    }
                    otroz = data.otros;
                    if(otroz.length > 0 ){
                        $('#otros-container').removeClass('d-none');
                        $('#add-otro-container').removeClass('d-none');
                        $('#tx-add-otro').attr('disabled',false)
                    }
                    for(o=0; o<otroz.length;o++){
                        iOtro = '<div id="control-otro-'+otroz[o].pivot.id+'" class="row">';
                        iOtro += '<div class="col-10">';
                        iOtro += '<input disabled class="st-input" type="text" value="'+otroz[o].pivot.tx_otro+'" maxlength="250">';
                        iOtro += '<input type="hidden" name="tx-otro-'+otroz[o].pivot.id+'" id="tx-otro-'+otroz[o].pivot.id+'" value="'+otroz[o].pivot.tx_otro+'">';
                        iOtro += '</div><div class="col-2">';
                        if(data.canEdit){
                            iOtro += '<i title="Eliminar este servicio" otro="'+otroz[o].pivot.id+'" id="btn-quitaserv-'+otroz[o].pivot.id+'" class="btn-menos del-service fa fa-minus form-control d-flex justify-content-center align-items-center"></i>';
                        }
                        iOtro += '</div></div>';
                        $('#otros-container').append(iOtro);
                    }
                    if(! data.canEdit){
                        $('.del-service').remove();
                        $('#add-otro-container').remove();
                        //$('#chk_cierre_op').trigger('click');

                    };

                    if(data.fh_cierre != '' && data.fh_cierre != null){
                        $('#chk_cierre_op').trigger('click');
                        $('.co_controlz').attr('disabled', true);
                        $('.btn-co_controlz').remove();
                        $('#chk-cierre-container').remove();
                        $('#add-otro-container').remove();
                        $('.del-service').remove();
                        $('#zend').remove();
                        $('#no-zend').html('Aceptar');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    err = (jqXHR.status == 419 || jqXHR.status == 401) ? 'Tu sesión ha caducado, por favor inicia sesión de nuevo' : (jqXHR.responseJSON.message || jqXHR.responseJSON.mensaje);
                    $('#btn-send').attr('disabled', true);
                    __crearMensaje(true, "Error", err);
                }
            });
        }else{
            $('#btn-send').attr('disabled', true);
        }
    });



    /**
     * Casilla de cierre de operación
     */
    $('#chk_cierre_op').on('click', function(e){
        $('.co-control').attr('disabled', !$(this).prop('checked'));
        if($(this).prop('checked')){
            $('.co-container').removeClass('d-none');
        }else{
            $('.co-container').addClass('d-none');
        }
        
    });


    /**Envia form ServFunerarios */
    function zendForm(f){
        $('#zend').attr('disabled', true);
        $('#modal-zagruzka').modal('show');
        //form.submit();
        var formData = new FormData(f);

        $.ajax({
            url: uStoreGesServFune,
            headers: {          
                'Accept': "application/json; charset=utf-8",
            },
            type: 'POST',
            data: formData,
            success: function(data){
                $('#modal-zagruzka').modal('hide');
                $('#modalCierreOperacion').modal('hide');
                $("#registroexitoso").on("hidden.bs.modal", function () {
                    location.href=""+uServFuneIndex;
                });
                $('#registroexitoso').modal('show');

            } ,
            error: function( jqXhr, textStatus, errorThrown ){
                //herror = jqXhr.responseJSON.mensaje || jqXhr.responseJSON.message;
                herror = '<ul>';
                elementz = jqXhr.responseJSON.errors;
                for(element in elementz){
                    console.log(elementz[element]);
                    herror += '<li>'+elementz[element] + '</li>';
                }
                herror += '</ul>';
                $('#modal-zagruzka').modal('hide');
                $('#modalCierreOperacion').modal('hide');
                __crearMensaje(true,"Error",""+herror);
                $('#zend').attr('disabled', false);
            },
            cache: false,
            contentType: false,
            processData: false
        });
    }


    /**
     * Valida casillas de servicios y campitos de otros
     */
    function validaOtrosYServicios(kontrol = null){

        let bypassOtros = true;
        if(kontrol !== null && 'otro' == kontrol.attr('d-otro')){
            if(kontrol.prop('checked')){
                $('#add-otro-container').removeClass('d-none');
                $('#tx-add-otro').attr('disabled', false);

            }else{
                $('#add-otro-container').addClass('d-none');
                $('#tx-add-otro').attr('disabled', true);
            }
        }
        let hasServices = false;
        
        $('input[id^="chk-srv-"]').each(function( index ) {
            if($(this).prop('checked')){
                hasServices = true;
                if('otro' == $(this).attr('d-otro')){
                    bypassOtros = $('input[id^="tx-otro-"]').length > 0;
                    if(!bypassOtros && kontrol == null){
                        $('#errAddOtro').html('Se debe agregar por lo menos un servicio');
                        document.getElementById('errAddOtro').style.display='inline';
                        __crearMensaje(true,"Error","Por favor revisa los campos marcados con rojo.");
                    }else{
                        if( document.getElementById('errAddOtro') !== null){
                            document.getElementById('errAddOtro').style.display='none';
                        }
                    }
                }
            }
        });
        if(!hasServices){
            $('#zend').attr('disabled', true);
            document.getElementById('error-servicios').style.display='inline';
            __crearMensaje(true,"Error","Por favor revisa los campos marcados con rojo.");
        }else{
            $('#zend').attr('disabled', false);
            document.getElementById('error-servicios').style.display='none';
        }

        return hasServices && bypassOtros;
    }


    /**
     * Boton de envio de formulario
     */
    $('#zend').on('click', function(e){
        let form = document.getElementById('frmServicioFune');
        let zend = validaOtrosYServicios();
        if (form.checkValidity() === true) {
            if($('#chk_cierre_op').prop('checked')){
                $('#modalCierreOperacion').modal('show');
            }else{
                if(zend){
                    zendForm(form);
                }
            }
        }else{
            $(form).find( ":valid" ).each(function (){
                $('#feedback-'+this.id).html('');
            });
            $(form).find( ":invalid" ).each(function (){
                if(this.validity.valueMissing) {
                    $('#feedback-'+this.id).html('Este campo es obligatorio');
                } else if(this.validity.typeMismatch) {
                    $('#feedback-'+this.id).html('Error de tipo.');
                } else if(this.validity.tooShort) {
                    $('#feedback-'+this.id).html('Es valor de este campo debe ser de ' + this.minlength + ' o más caracteres.');
                } else if(this.validity.rangeOverflow) {
                    $('#feedback-'+this.id).html('Es valor de este campo debe ser un número entre ' + this.min + ' y ' + this.max);
                } else if(this.validity.tooLong) {
                    $('#feedback-'+this.id).html('Es valor de este campo no debe pasar de ' + this.maxlength + ' caracteres.');
                } else if(this.validity.patternMismatch) {
                    $('#feedback-'+this.id).html($(this).attr('invalid-regex'));
                }
                console.log(this.id);
            });
            __crearMensaje(true,"Error","Por favor revisa los campos marcados con rojo.");
            form.classList.add('was-validated');
        }
    });


    /**
     * Boton de envio de formulario en modal
     */
    $('#realZend').on('click', function(e){
        let form = document.getElementById('frmServicioFune');
        if(validaOtrosYServicios()){
            zendForm(form);
        }

    });



    /**
     * Casillas de servicios funerarios
     */
    $('input[id^="chk-srv-"]').on('click', function(e){
        validaOtrosYServicios($(this));
    });



    /**
     * Agregar otro (textbox)
     */
    $('#btn-addotro').on('click', function(e){
        if($('#tx-add-otro').val().trim()==''){
            $('#errAddOtro').html('Falta escribir la descripción del servicio');
            document.getElementById('errAddOtro').style.display='inline';
        }else{
            document.getElementById('errAddOtro').style.display='none';
            microtime = Math.floor(Date.now() / 1000);
            iOtro = '<div id="control-otro-'+microtime+'" class="row">';
            iOtro += '<div class="col-10">';
            iOtro += '<input disabled class="st-input" type="text" value="'+$('#tx-add-otro').val()+'" maxlength="250">';
            iOtro += '<input type="hidden" name="tx-otro-'+microtime+'" id="tx-otro-'+microtime+'" value="'+$('#tx-add-otro').val()+'">';
            iOtro += '</div><div class="col-2">';
            iOtro += '<i title="Eliminar este servicio" otro="'+microtime+'" id="btn-quitaserv-'+microtime+'" class="btn-menos del-service fa fa-minus form-control d-flex justify-content-center align-items-center"></i>';
            iOtro += '</div></div>';
            $('#otros-container').removeClass('d-none');
            $('#otros-container').append(iOtro);
            $('#tx-add-otro').val('');
        }
    });


    /**
     * Descripción del servicio
     */
    $('#tx-add-otro').on('keyup blur',function(e){
        if($(this).val().trim()==''){
            document.getElementById('errAddOtro').style.display='inline';
        }else{
            document.getElementById('errAddOtro').style.display='none';
        }
    });


    /**
     * Eliminar servicios "otros"
     */
    $('body').on('click', '.del-service', function(e) {
        kual = $(this).attr('otro');
        $('#control-otro-' + kual).remove();
    });



    /**
     * Boton de busar agencia
     */
    $('.select-agencia').on('change', function(){
        const baz = $(this).val();
        $('#btn-send').attr('disabled', true);
        if (baz !='' && parseInt(baz)>0) {
            $.ajax({
                url: uConsultaAgenciasAlcaldia + '/' + baz,
                dataType: 'json',
                type: 'GET',
                contentType: 'application/json',

                success: function (data) {
                    // Quitar agencias
                    $('#id_agencia_funeraria option').each(function() {
                        if ( $(this).val() !== '' ) {
                            $(this).remove();
                        }
                    });
                    // Poner las nuevas
                    agencias = data.agencias;
                    if(agencias.length > 0){
                        for(a=0;a<agencias.length;a++){
                            idAgencia = agencias[a].id;
                            nbAgencia = agencias[a].nb_agencia;
                            $('#id_agencia_funeraria').append('<option value="'+idAgencia+'">'+nbAgencia+'</option>');
                            //$('#btn-send').attr('disabled', false);
                        }
                    }else{
                        __crearMensaje(true, "Error", "La demarcación territorial seleccionada no cuenta con agencias funerarias");
                        $('#ajax-services-container').html('');
                        $('#btn-send').attr('disabled', true);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    err = (jqXHR.status == 419 || jqXHR.status == 401) ? 'Tu sesión ha caducado, por favor inicia sesión de nuevo' : (jqXHR.responseJSON.message || jqXHR.responseJSON.mensaje);
                    $('#btn-send').attr('disabled', true);
                    __crearMensaje(true, "Error", err);
                }
            });
        }else{
            $('#btn-send').attr('disabled', true);
        }
    });



    /**
     * Campos de acuerdo a servicios funerario ofrecidos por agencia
     * 
     */
    $('.ag-fragment').on('change', function(){
        $('#ajax-services-container').html('');
        $('#btn-send').attr('disabled', true);
        const baz = $(this).val();
        if (baz !='' && parseInt(baz)>0) {
            $.ajax({
                url: uFragmentAgencia + '/' + baz,
                dataType: 'json',
                type: 'GET',
                contentType: 'application/json',
                success: function (data) {
                    servicios = data.servicios;
                    if(servicios.length > 0){
                        for(s=0;s<servicios.length;s++){
                            thisService = servicios[s];
                            $('#ajax-services-container').append(thisService.fragment);
                        }
                        otritos = data.otros;
                        $('#ajax-services-container').append(otritos);

                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    err = (jqXHR.status == 419 || jqXHR.status == 401) ? 'Tu sesión ha caducado, por favor inicia sesión de nuevo' : (jqXHR.responseJSON.message || jqXHR.responseJSON.mensaje);
                    $('#btn-send').attr('disabled', true);
                    __crearMensaje(true, "Error", err);
                }
            });
        }else{
            $('#btn-send').attr('disabled', true);
        }
    });



    /**
     * Casillas de servicios en Op Servicios Funerarios
     */
    $('body').on('click', '.opsf-services-check', function(e) {
        kual = $(this).attr('id');
        isChecked = $(this).prop('checked');
        hasServices = false;
        if(isChecked){
            $('.' + kual + '-content').removeClass('d-none');
            $('.' + kual + '-control').attr('disabled', false);
        }else{
            $('.' + kual + '-content').addClass('d-none');
            $('.' + kual + '-control').attr('disabled', true);
        }

        $('.opsf-services-check').each(function( index ) {
            if($(this).prop('checked')){
                hasServices = true;
            }
        });
        if(!hasServices){
            $('#btn-send').attr('disabled', true);
            document.getElementById('error-servicios').style.display='inline';
            
        }else{
            $('#btn-send').attr('disabled', false);
            document.getElementById('error-servicios').style.display='none';
        }
        
    });



    /**
     * Fechas salida > que fechas entrada
     */
    $('body').on('change', '.start-date, .end-date', function(e) {
        kual = $(this).attr('id');
        val = $(this).val();
        partes = kual.split('_');
        s = partes[2];
        foobar = false;
        if(kual.indexOf('entrada')>-1){
            // campo fecha entrada -> related salida
            related = '#fh_salida_' + s;
            atttr = 'min';
            // verificar salida
            baz = new Date();
            foo = new Date(val);
            foo.setDate(foo.getDate() + 2);
            if(foo.getTime() > baz.getTime()){ //Fecha entrada + 2 dias es mayor a la de hoy
                bar2 = foo.toLocaleString('fr-CA', {
                    year:"numeric", month: "2-digit", day:"2-digit"
                });
                foobar = true;
            }else{
                bar2 = val;
            }
        }else{
            // Campo fecha salida -> related entrada
            foo = new Date(val);
            foo.setDate(foo.getDate() + 2);
            bar = foo.toLocaleString('fr-CA', {
                year:"numeric", month: "2-digit", day:"2-digit"
            });

            related = '#fh_entrada_' + s;
            atttr = 'max';
            val = bar;
        }
        $(related).attr(atttr, val);
        if(foobar){
            $(related).attr('max', bar2);
        }
    });



    /**
     * Fechas minimas = fecha de defuncion
     */
    $('.fh-def-op').on('change', function(){
        laDate = $(this).val();
        $('.start-date').each(function( index ) {
            $(this).attr('min', laDate);
            
        });
        $('.end-date').each(function( index ) {
            $(this).attr('min', laDate);
        });
    });

    //TODO
    // Al enviar ver que por lo menos un .opsf-services-check esté seleccionado
    // Al enviar si chk-otros esta seleccionado, por lo menos un chk-otros-control debe estar seleccionado
    // Validacion detalle servicio: fechas salida > fechas entrada


    /**Envia form ServFunerarios */
    // clic en el guardar
    // muestra modal
    // clic en el realZend

    function zendFormCustodio(f){
        $('#zendOpServicio').attr('disabled', true);
        $('#modal-zagruzka').modal('show');
        //form.submit();
        var formData = new FormData(f);

        $.ajax({
            url: uSendCustodio,
            headers: {          
                'Accept': "application/json; charset=utf-8",
            },
            type: 'POST',
            data: formData,
            success: function(data){
                $('#modal-zagruzka').modal('hide');
                $('#modalCustodioConfirm').modal('hide');
                $("#registroexitoso").on("hidden.bs.modal", function () {
                    location.href=""+uOpServFuneIndex;
                });
                $('#registroexitoso').modal('show');

            } ,
            error: function( jqXhr, textStatus, errorThrown ){
                herror = jqXhr.responseJSON.mensaje || jqXhr.responseJSON.message;
                $('#modal-zagruzka').modal('hide');
                $('#modalCustodioConfirm').modal('hide');
                __crearMensaje(true,"Error",""+herror);
                $('#zendOpServicio').attr('disabled', false);
            },
            cache: false,
            contentType: false,
            processData: false
        });
    }

    $('#zendOpServicio').on('click', function(e){
        let form = document.getElementById('frmSendCustodio');
        if (form.checkValidity() === true) {
            $('#modalCustodioConfirm').modal('show');
        }else{
            $(form).find( ":valid" ).each(function (){
                $('#feedback-'+this.id).html('');
            });
            $(form).find( ":invalid" ).each(function (){
                if(this.validity.valueMissing) {
                    $('#feedback-'+this.id).html('Este campo es obligatorio');
                } else if(this.validity.typeMismatch) {
                    $('#feedback-'+this.id).html('Error de tipo.');
                } else if(this.validity.tooShort) {
                    $('#feedback-'+this.id).html('Es valor de este campo debe ser de ' + this.minlength + ' o más caracteres.');
                } else if(this.validity.rangeOverflow) {
                    $('#feedback-'+this.id).html('Es valor de este campo debe ser un número entre ' + this.min + ' y ' + this.max);
                } else if(this.validity.tooLong) {
                    $('#feedback-'+this.id).html('Es valor de este campo no debe pasar de ' + this.maxlength + ' caracteres.');
                } else if(this.validity.patternMismatch) {
                    $('#feedback-'+this.id).html($(this).attr('invalid-regex'));
                }
                console.log(this.id);
            });
            __crearMensaje(true,"Error","Por favor revisa los campos marcados con rojo.");
            form.classList.add('was-validated');
        }
    });

    $('#realZendOpServicio').on('click', function(e){
        let form = document.getElementById('frmSendCustodio');
        zendFormCustodio(form);
    });





});