  /**
   * ajax para validar la curp
   *
   */
  $('#tx_curp').on('keyup blur', function (e) {
      if ($(this).val() != '' && /^(([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d))$/.test($(this).val())) {
          var laCurp = $(this).val();
          var isServFunerario = $('#valida-curp-sf').length > 0;
          $.ajax({
              url: uCurp,
              dataType: 'json',
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({
                  tx_curp: $(this).val(),
                  validaCurpServFunerario: isServFunerario 
              }),
              success: function (data) {
                  $('#nb_persona').val(data.txNombres);
                  $('#tx_apellido_paterno').val(data.txApellidoPaterno);
                  $('#tx_apellido_materno').val(data.txApellidoMaterno);
                  $('#fh_nacimiento').val(data.fhNacimiento);
                  $('#nu_edad_year').val(data.nuEdadYear);
                  $('#nu_edad_mes').val(data.nuEdadMes);
                  $('#id_sexo').val(data.sexo);

                  $('#btn-send').attr('disabled', false);
              },
              error: function (jqXHR, textStatus, errorThrown) {
                  err = (jqXHR.status == 419 || jqXHR.status == 401) ? 'Tu sesión ha caducado, por favor inicia sesión de nuevo' : jqXHR.responseJSON.message || jqXHR.responseJSON.respuesta;
                  $('#nb_persona').val('');
                  $('#tx_apellido_paterno').val('');
                  $('#tx_apellido_materno').val('');
                  $('#fh_nacimiento').val('');
                  $('#nu_edad_year').val('');
                  $('#nu_edad_mes').val('');
                  $('#id_sexo').val('');
                  $('#btn-send').attr('disabled', true);
                  $('#tx_curp').val('');
                  __crearMensaje(true, "Error", err);
              }
          });
      } else {
          $('#nb_persona').val('');
          $('#tx_apellido_paterno').val('');
          $('#tx_apellido_materno').val('');
          $('#fh_nacimiento').val('');
          $('#nu_edad_year').val('');
          $('#nu_edad_mes').val('');
          $('#id_sexo').val('');
          $('#btn-send').attr('disabled', true);
      }
  });


  /**
   * ajax para validar el CP
   *
   */
  $('#tx_codigo_postal').on('keyup blur', function (e) {
      if ($(this).val() != '' && /^[0-9]{5}$/.test($(this).val())) {
          $.ajax({
              url: uColonia,
              dataType: 'json',
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({
                  tx_cp: $(this).val()
              }),
              success: function (data) {
                  // Quitar estado
                  $('#id_entidad option').each(function () {
                      if ($(this).val() !== '') {
                          $(this).remove();
                      }
                  });
                  // Quitar municipio
                  $('#id_municipio option').each(function () {
                      if ($(this).val() !== '') {
                          $(this).remove();
                      }
                  });
                  // Quitar Colonias
                  $('#id_colonia option').each(function () {
                      if ($(this).val() !== '') {
                          $(this).remove();
                      }
                  });
                  colonias = data.colonias;
                  if (colonias.length > 0) {
                      // Poner Edo
                      $('#id_entidad').append('<option value="' + data.entidad.id + '">' + data.entidad.nb_entidad + '</option>');
                      // Poner Mpio
                      $('#id_municipio').append('<option value="' + data.municipio.id + '">' + data.municipio.nb_municipio + '</option>');
                      // Poner colonias
                      for (c = 0; c < colonias.length; c++) {
                          idColonia = colonias[c].id;
                          nbColonia = colonias[c].nb_asentamiento;
                          $('#id_colonia').append('<option value="' + idColonia + '">' + nbColonia + '</option>');
                      }
                  } else {
                      $('#tx_codigo_postal').val('');
                      __crearMensaje(true, "Error", 'El codigo postal no es válido');
                  }
                  $('#btn-send').attr('disabled', false);
              },
              error: function (jqXHR, textStatus, errorThrown) {
                  err = jqXHR.status == 419 ? 'Tu sesión ha caducado, por favor inicia sesión de nuevo' : jqXHR.responseJSON.message || jqXHR.responseJSON.respuesta;
                  $('#btn-send').attr('disabled', true);
                  __crearMensaje(true, "Error", err);
              }
          });
      } else {
          $('#btn-send').attr('disabled', true);
      }
  });


  /**
   * Modalin terminos
   *
   */
  $('#st_read_policy').on('click', function (e) {
      $('#btn-send').attr('disabled', !($(this).prop('checked')));
      $('#btn-nel').attr('disabled', ($(this).prop('checked')));

  });
