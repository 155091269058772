$(document).ready(function () {
    if ($('#registrarUsuario').length) {
        $('.st-input').css('max-width', 'none'); /* quita la limitante del max-width y que se controle x bootstrap */
        var opciones = '<option value="">Seleccionar</option>';
        usuariosXRol.forEach((e) =>
            opciones += "<option data-data='" + JSON.stringify(e) + "' value=" + e.idUsuario + ">" + e.login + "</option>"
        );

        $('#idUsuario').append(opciones);
        $('#idUsuario').focus();

        $('input:not(input[type="file"]), select:not([name="idUsuario"]').on('change', function (e) {
            upd_dato(e);
        });

        $('input[name="id_clasificacion"]').off('change');

        if ($('.desrol').text() == PERMISO.ADMINDES) {
            /* para esconder el boton de alta de cemterio */
            $('#agregacementerio').remove();
        } else {
            $('#s_cementerio').off('change');
        }

        $('#agregacementerio').on('click', function (e) {
            if ($('#s_cementerio').val() == null || $('#s_cementerio').val() == '') {
                $('#s_cementerio').focus();
                $('.s_cementerioobli').removeClass('d-none');
                return false;
            }
            $('.s_cementerioobli').addClass('d-none');
            alta_cementerio_usuario(this);
            $('#s_cementerio').val('');
        });

        $('#regresa').on('click', function (e) {
            $.redirect(ubase + "/usuarios", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });

        $('input[name="id_modalidad"]').on('change', function (e) {
            modalidades(e);
        });

        window.modalidades = function (e) {
            if (e.target.value != "") {
                $('.error_modalidad').addClass('d-none');
            }
        }

        $('select[name^="hm_"], input[name^="st_full"]').on('change', function (e) {
            $('.error_horario').addClass('d-none');
            if (e.target.id.indexOf('abre') != -1) {
                var abre = e.target.value.replace(':', '');
                scierra = e.target.id.replace('abre', 'cierra');
                var cierra = $('#' + scierra).val();
                cierra = cierra.replace(':', '');
                if (parseInt(abre) > parseInt(cierra) & parseInt(cierra) != 0) {
                    error_horario(e);
                    e.preventDefault();
                    return;
                }
            }
            if (e.target.id.indexOf('cierra') != -1) {
                var cierra = e.target.value.replace(':', '');
                sabre = e.target.id.replace('cierra', 'abre');
                var abre = $('#' + sabre).val();
                abre = abre.replace(':', '');
                console.log('cierra=' + cierra + ' abre=' + abre);
                if (parseInt(abre) > parseInt(cierra)) {
                    error_horario(e);
                    e.preventDefault();
                    return;
                }
            }

            upd_dato(e, 'cementerio_horario');
        });


        window.upd_dato = function (e, estatus = '') {
            var Data1 = {};
            var quedato = e.currentTarget.id;
            var valor = e.currentTarget.value;


            if (e.currentTarget.type == "checkbox") {
                valor = e.currentTarget.checked;
            }

            if (e.currentTarget.type == 'radio') {
                quedato = e.currentTarget.name;
            }

            if (e.currentTarget.name == 'id_modalidad') {
                quedato = e.currentTarget.name;
                Data1['es_checked'] = e.currentTarget.checked;
                valor = e.currentTarget.value;
            }

            if (e.currentTarget.name == 'id_clasificacion') {
                Data1['es_civil'] = $('#es_civil').prop('checked');
                Data1['es_concesionario'] = $('#es_concesionario').prop('checked');
                Data1['es_comunitario'] = $('#es_comunitario').prop('checked');

                if (Data1['es_concesionario']) {
                    $('#es_comunitario').attr('disabled', true);
                    $('#es_civil').attr('disabled', true);
                } else if (Data1['es_civil'] || Data1['es_comunitario']) {
                    $('#es_concesionario').attr('disabled', true);
                } else {
                    $('#es_comunitario').attr('disabled', false);
                    $('#es_civil').attr('disabled', false);
                    $('#es_concesionario').attr('disabled', false);
                }
                $('#s_cementerio').val('default').selectpicker("refresh");
            }

            Data1[quedato] = valor;
            Data1['idUsuario'] = $('#idUsuario').val();

            if (e.currentTarget.name == 's_cementerio') {
                $('.s_cementerioobli').addClass('d-none');
                Data1['id_cementerio'] = valor;
                delete Data1[quedato];
                que_clasificacion($('#' + e.currentTarget.name + ' option:selected').text());
            }

            $.ajax({
                dataType: 'json',
                type: 'post',
                url: ubase + '/actualizaUsuarioCementerio',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    if (e.currentTarget.type == "button") {
                        $('#registroexitoso').modal('show');
                        $("#registroexitoso").on("hidden.bs.modal", function () {
                            $.redirect(ubase + "/usuarios", {
                                '_token': $('meta[name="csrf-token"]').attr('content')
                            });
                        })
                    }
                },

                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status === 401) {
                        __crearMensaje(true, "Error", "Tu sesión ha caducado");
                    }
                    if (jqXHR.status === 418) {
                        __crearMensaje(true, "Error", data.mensaje || data.message);
                    }
                    if (jqXHR.status === 422) {
                        __crearMensaje(true, "Error", "" + jqXHR.responseJSON.data);
                        console.log(quedato);
                        $('#' + quedato).trigger('click');
                    }
                }
            });
        }


        window.que_clasificacion = function (opcion) {
            $('input[name="id_clasificacion"]').prop('checked', false);
            if (opcion.indexOf(CLASIFICACION_CEMENTERIO_DES.CIVIL) != -1) {
                $('[name="id_clasificacion"][value="' + CLASIFICACION_CEMENTERIO.CIVIL + '"]').prop('checked', true);
            }
            if (opcion.indexOf(CLASIFICACION_CEMENTERIO_DES.CONCESIONARIO) != -1) {
                $('[name="id_clasificacion"][value="' + CLASIFICACION_CEMENTERIO.CONCESIONARIO + '"]').prop('checked', true);
            }
            if (opcion.indexOf(CLASIFICACION_CEMENTERIO_DES.COMUNITARIO) != -1) {
                $('[name="id_clasificacion"][value="' + CLASIFICACION_CEMENTERIO.COMUNITARIO + '"]').prop('checked', true);
            }
            muestra_CivilComunitarioConsecionario();
        }

        $('#estatus').on('click', function (e) {
            e.preventDefault();
            var forma = $('#frm_registrausuario')[0];
            var ok_horario = horarios_correctos();
            // console.log($('#s_cementerio').val());
            // console.log($('.desrol').text());
            // console.log(PERMISO.ADMINDES);
            if (forma.checkValidity() === false || ok_horario.estatus == false || $('input[name="id_clasificacion"]:checked').length == 0 ||
                ($('.btn-menos').length == 0 & $('.desrol').text() == PERMISO.RESPONSABLEDES) /* checa si ya tiene un cementerio asignado */ ||
                $('input[type="file"].required:not([data-archivo])').length > 0 ||
                ($('input[name="id_modalidad"]:checked').length == 0 & $('.desrol').text() == PERMISO.ADMINDES) ||
                ($('#s_cementerio').val() == '' & $('.desrol').text() == PERMISO.ADMINDES)
            ) {
                forma.classList.add('was-validated');

                if ($('input[name="id_clasificacion"]:checked').length == 0) {
                    $('.error_clasificacion').removeClass('d-none');
                }

                if (ok_horario.estatus == false) {
                    $('.error_horario').html(ok_horario.descripcion);
                    $('.error_horario').removeClass('d-none');
                }

                if ($('.btn-menos').length == 0 & $('.desrol').text() == PERMISO.RESPONSABLEDES) {
                    $('.error_asignacementerio').removeClass('d-none');
                }
                if ($('input[type="file"].required:not([data-archivo])').length > 0) {
                    $('input[type="file"].required:not([data-archivo])').parent().next().removeClass('d-none');
                }

                if ($('input[name="id_modalidad"]:checked').length == 0 & $('.desrol').text() == PERMISO.ADMINDES) {
                    $('.error_modalidad').removeClass('d-none');
                }

                if ((parseInt(+$('#s_cementerio').val()) == 0) & $('.desrol').text() == PERMISO.ADMINDES) {
                    $('.s_cementerioobli').removeClass('d-none');
                }

                return;
            }
            upd_dato(e);
        })


        window.horarios_correctos = function () {
            var regresa = {
                estatus: false,
                descripcion: 'Al menos debe definir un dia en el horario.'
            };
            /* si encuentre un valor diferente de cero es correcto */
            $('select[name^="hm_"] option:selected').each(function (index) {
                if ($(this).val() != '0:00') {
                    regresa.estatus = true;
                    return false;
                }
            });

            //if (regresa.estatus==true) {
            /* si hay un checked full es correcto */
            if ($('input[name^="st_full"]:checked').length > 0) {
                regresa.estatus = true;
            }
            //}

            if (regresa.estatus == true) {
                $('select[name^="hm_abre"]').each(function (index) {
                    var abre = $(this).val().replace(':', '');
                    var scierra = $(this)[0].id.replace('abre', 'cierra');
                    var cierra = $('#' + scierra).val();
                    cierra = cierra.replace(':', '');
                    /* si encuentra que una apertura es mayor que el cierre hay error */
                    if (parseInt(abre) > parseInt(cierra)) {
                        $(this).focus()
                        regresa.estatus = false;
                        regresa.descripcion = "La apertura es mayor que el cierre de " + $(this)[0].id.replace('hm_abre_', '');
                        return false;
                    }
                });
            }

            return regresa;
        }


        window.error_horario = function (e) {
            // $('#error_horario').remove()
            // str = '<div class="modal fade" id="error_horario" tabindex="-1" data-backdrop="static" role="dialog" aria-hidden="true">' +
            //     '<div class="modal-dialog modal-sm" role="document">' +
            //     '<div class="modal-content rounded-modal" >' +
            //     '<div class="modal-body text-center text-danger">' +
            //     'Error en el horario' +
            //     '</div>' +
            //     '<div class="modal-footer justify-content-center border-0">' +
            //     '<div type="button" class="btn btn-pine-gree" data-dismiss="modal" >Cerrar</div>' +
            //     '</div>' +
            //     '</div>' +
            //     '</div>' +
            //     '</div>';
            // $('body').append(str);
            // $('#error_horario').modal('show')
            // $("#error_horario").on("hidden.bs.modal", function () {
            //     $(e.target).focus();
            // });
        }


        window.deseaDarBajaCementerio = function (e) {
            $('#deseaDarBajaCementerio').remove()
            str = '<div class="modal fade" id="deseaDarBajaCementerio" tabindex="-1" data-backdrop="static" role="dialog" aria-hidden="true">' +
                '<div class="modal-dialog modal-md" role="document">' +
                '<div class="modal-content rounded-modal" >' +
                '<div class="modal-body registroexitoso-lg mt-4 -mb-2">' +
                '<p>El cementerio <b>' + $(e).data('nombrecementerio') + '</b> quedará sin un Responsable asignado.</p>' +
                '<p class="m-0">¿Desea continuar?</p>' +
                '</div>' +
                '<div class="modal-footer justify-content-center border-0">' +
                '<div type="button" class="btn btn-cancelar" data-dismiss="modal" >Cancelar</div>' +
                '<div type="button" class="btn btn-pine-gree siBajaCementerio" >Aceptar</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            $('body').append(str);
            $('#deseaDarBajaCementerio').modal('show')
            $(".siBajaCementerio").on("click", function () {
                siBajaCementerio(e);
            });
        }


        window.alta_cementerio_usuario = function (e) {
            var Data1 = {};
            Data1['idUsuario'] = $('#idUsuario').val();
            Data1['id_cementerio'] = $('#s_cementerio').val();
            $.ajax({
                dataType: 'json',
                type: 'post',
                url: ubase + '/registrarUsuarioCementerio',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    $('.error_asignacementerio').addClass('d-none');
                    $('.cementerios .form-group').remove();
                    muestra_cementerios(data.data);
                    $('#s_cementerio').val('default').selectpicker("refresh");
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    herror = jqXhr.responseJSON.mensaje || jqXhr.responseJSON.message;
                    modal = jqXhr.responseJSON.toModal
                    if (modal) {
                        $('#yaEstaAsignadoCementerio').modal('show');
                    } else {
                        __crearMensaje(true, "Error", "" + herror);
                    }
                }
            });
        }


        window.muestra_cementerios = function (data) {
            $('.cementerios').children().remove();
            for (var x in data) {
                var id = data[x].pivot.id_cementerio + '_' + data[x].pivot.id_responsable;
                var id_cementerio = data[x].pivot.id_cementerio;
                var id_responsable = data[x].pivot.id_responsable;
                var areanum = $('.btn-menos').length + 1;
                var areavalue = data[x].nb_cementerio + ', ' + data[x].alcaldia.nb_municipio + ', ' + data[x].clasificacion.nb_clasificacion;
                var areahtml = '<div class="col-lg-12 form-group d-flex align-items-center" id="div_' + id + '">' +
                    '<label for="horizontal" class="control-label form-label-cdmx col-lg-2 pl-0 mb-0">Cementerio ' + areanum + '</label>' +
                    '<div class="col-lg-11 d-flex flex-wrap" >' +
                    '<input class="form-control form-control-custom col-lg-10" type="text" name="area"' +
                    'id="cr_' + id + '" value="' + areavalue + '" disabled>' +
                    '<i  id="btn-menos-' + id + '" data-id_cementerio="' + id_cementerio + '" data-id_responsable="' + id_responsable +
                    '" data-nombrecementerio="' + data[x].nb_cementerio + '"' +
                    '" class="btn-menos fa fa-minus form-control d-flex justify-content-center align-items-center"></i>' +
                    '</div>' +
                    '</div>';
                $('.cementerios').append(areahtml);
                $('#btn-menos-' + id).on('click', function (e) {
                    deseaDarBajaCementerio(this);
                });
            }
        }


        window.siBajaCementerio = function (e) {
            $('#deseaDarBajaCementerio').modal('hide')
            var Data1 = {};
            Data1['idUsuario'] = $(e).data('id_responsable');
            Data1['id_cementerio'] = $(e).data('id_cementerio');
            $.ajax({
                dataType: 'json',
                type: 'delete',
                url: ubase + '/bajaUsuarioCementerio',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    $('.cementerios .form-group').remove();
                    muestra_cementerios(data.data);
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    var errores = jqXhr.responseJSON.errors;
                }
            });
        }


        var options = {
            ajax: {
                url: ubase + '/cementeriosbuscar',
                type: 'POST',
                dataType: 'json',
                data: function () { // This is a function that is run on every request
                    return {
                        q: '{{{q}}}',
                        //    civil: ($('#es_civil').prop('checked')==true & $('.desrol').text()==PERMISO.RESPONSABLE ? '1' : '0'),
                        //    concesionario: ($('#es_concesionario').prop('checked')==true & $('.desrol').text()==PERMISO.RESPONSABLE ? '1' : '0'),
                        //    comunitario: ($('#es_comunitario').prop('checked')==true & $('.desrol').text()==PERMISO.RESPONSABLE ? '1' : '0')
                        civil: ($('#es_civil').prop('checked') ? '1' : '0'),
                        concesionario: ($('#es_concesionario').prop('checked') ? '1' : '0'),
                        comunitario: ($('#es_comunitario').prop('checked') ? '1' : '0')
                    }
                }
            },
            minLength: 4,
            liveSearch: true,
            locale: {
                emptyTitle: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                errorText: 'No se encontraron cementerios',
                statusSearching: 'Buscando ....',
                searchPlaceholder: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusInitialized: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusTooShort: 'Por favor Tecleé más dígitos',
                currentlySelected: 'Actualmente seleccionado',
                statusNoResults: 'No se encontraron cementerios'
            },
            log: 'error',
            preprocessData: function (data) {
                var i, l = data.length,
                    array = [];
                if (l) {
                    for (i = 0; i < l; i++) {
                        array.push($.extend(true, data[i], {
                            text: data[i].nb_cementerio + ',' + data[i].nb_municipio + ',' + data[i].nb_clasificacion,
                            value: data[i].id,
                            data: {
                                // subtext: data[i].estado
                            }
                        }));
                    }
                }
                return array;
            }
        };


        $(function () {
            $('#s_cementerio').selectpicker({
                liveSearch: true,
                styleBase: 'form-control form-control-cdmx'
            }).filter('.with-ajax').ajaxSelectPicker(options);
        });

        $('#idUsuario').on('change', function (e) {
            muestra_usuario(e);
        });


        $('input[name="id_clasificacion"]').on('change', function (e) {

            if ($('.desrol').text() == PERMISO.ADMINDES) {
                $('input[name=id_clasificacion]').not(this).prop('checked', false);
            }
            cambio_clasi(e);

        });


        $('.fa-caret-square-down').on('click', function (e) {
            var anterior = $(this).prev();
            $(anterior).attr('size', $(anterior).children('option').length)
        });


        window.cambio_clasi = function (e) {
            $('.error_clasificacion').addClass('d-none');
            $('.divcivil').addClass('d-none');
            $('.divcivil input:not(input[type="file"])').prop('required', false);
            $('.divcomunitario').addClass('d-none');
            $('.divcomunitario input:not(input[type="file"])').prop('required', false);
            $('.divconcesionario').addClass('d-none');
            $('.divconcesionario input:not(input[type="file"])').prop('required', false);


            if ($('.desrol').text() == PERMISO.RESPONSABLEDES) {
                if (e.target.value == CLASIFICACION_CEMENTERIO.CONCESIONARIO) {
                    $('#es_civil').prop('checked', false);
                    $('#es_comunitario').prop('checked', false);
                } else {
                    $('#es_concesionario').prop('checked', false);
                }
            }

            muestra_CivilComunitarioConsecionario();
            upd_dato(e);
        }


        window.muestra_CivilComunitarioConsecionario = function () {
            if ($('#es_civil').prop('checked') == true) {
                $('.divcivil').removeClass('d-none');
                $('.divcivil input:not(input[type="file"])').prop('required', true);
                $('.divcivil input[type="file"]').addClass('required');
                que_rol('divcivil');
            } else {
                $('.divcivil').addClass('d-none');
                $('.divcivil input:not(input[type="file"])').prop('required', false);
                $('.divcivil input[type="file"]').removeClass('required');
                $('.divcivil .responsable').addClass('d-none').prop('required', false);
                $('.divcivil .administrador').addClass('d-none').prop('required', false);
                quita_rol('divcivil');
            }

            if ($('#es_comunitario').prop('checked') == true) {
                $('.divcomunitario').removeClass('d-none');
                $('.divcomunitario input:not(input[type="file"])').prop('required', true);
                $('.divcomunitario input[type="file"]').addClass('required');
                que_rol('divcomunitario');
            } else {
                $('.divcomunitario').addClass('d-none');
                $('.divcomunitario input:not(input[type="file"])').prop('required', false);
                $('.divcomunitario input[type="file"]').removeClass('required');
                quita_rol('divcomunitario');
            }

            if ($('#es_concesionario').prop('checked') == true) {
                $('.divconcesionario').removeClass('d-none');
                $('.divconcesionario input:not(input[type="file"])').prop('required', true);
                $('.divconcesionario input[type="file"]').addClass('required');
                que_rol('divconcesionario');
            } else {
                $('.divconcesionario').addClass('d-none');
                $('.divconcesionario input:not(input[type="file"])').prop('required', false);
                $('.divconcesionario input[type="file"]').removeClass('required');
                quita_rol('divconcesionario');
            }
        }


        window.que_rol = function (div) {
            if ($('.desrol').text() == PERMISO.ADMINDES) {
                $('.' + div + ' .administrador').removeClass('d-none').prop('required', true);
                $('.' + div + ' .responsable').addClass('d-none').prop('required', false);
                return;
            }
            if ($('.desrol').text() == PERMISO.RESPONSABLEDES) {
                $('.' + div + ' .responsable').removeClass('d-none').prop('required', true);
                $('.' + div + ' .administrador').addClass('d-none').prop('required', false);
                return;
            }
        }


        window.quita_rol = function (div) {
            $('.' + div + ' .responsable').addClass('d-none').prop('required', false);
            $('.' + div + ' .administrador').addClass('d-none').prop('required', false);
        }


        window.muestra_usuario = function (e) {
            $('input[id^="id_file_"]').next().next().remove(); /*remueve el boton de visualizar */
            $('input[id^="id_file_"]').removeAttr('data-archivo');; /*remueve el boton de visualizar */
            $('select[name^="hm"]').each(function (e) {
                $('#' + this.id + ' option[value="0:00"]').prop('selected', true);
            });
            $('#id_tipo_identificacion option[value=""]').prop('selected', true)
            if (e.target.value == "") {
                $('.seluser').prop('disabled', true);
                $('.seluser').addClass('disabled');
                return;
            }
            var data = $('#idUsuario option:selected').data('data');
            var curp = data.curp == null ? 'Sin curp' : data.curp;
            $('#curp').val(curp);
            $('#nombre').val(data.nombre);
            $('#primerApellido').val(data.primerApellido);
            $('#segundoApellido').val(data.segundoApellido);
            $('#telVigente').val(data.telVigente);

            if (data.descripcionRol == PERMISO.RESPONSABLEDES) {
                muestra_perfil(data.responsable);
                $('#div_modalidades').addClass('d-none');
            }
            if (data.descripcionRol == PERMISO.ADMINDES) {
                muestra_perfil(data.administrador);
                muestra_picker(data.administrador.cementerio);
                $('#div_modalidades').removeClass('d-none');
                $('#div_clasificacion').addClass('d-none');
                $('input[name="id_clasificacion"]').prop('required', false);
                $('#tx_area').val(data.administrador.tx_area);
                $('#tx_funciones').val(data.administrador.tx_funciones);
                muestra_modalidades(data.administrador.modalidades);
            }

            alta_usuario();
        }


        window.muestra_picker = function (data) {
            if (data != null) {
                if ($("#s_cementerio option[value='" + data.id + "']").length == 0) {
                    $('#s_cementerio').append('<option value="' + data.id + '" selected="selected">' +
                        data.nb_cementerio + ', ' + data.alcaldia.nb_municipio + ', ' + data.clasificacion.nb_clasificacion + '</option>').selectpicker('refresh');
                }
                var text = $("#s_cementerio option[value='" + data.id + "']").text();
                $($('#s_cementerio')[0].parentNode).find('.filter-option-inner-inner').text(text);
                que_clasificacion(text);
            } else {
                $('#s_cementerio').find('option').remove();
                $($('#s_cementerio')[0].parentNode).find('.filter-option-inner-inner').text('')
                $('.dropdown-item.active.selected').children().remove();
            }
        }


        window.muestra_modalidades = function (modalidades) {
            $('input[name="id_modalidad"]').prop('checked', false);
            $.each(modalidades, function (key, value) {
                console.log(key + ':' + value);
                $('input[name="id_modalidad"][value=' + value.id_modalidad + ']').prop('checked', true);
            })
        }


        window.muestra_perfil = function (perfil) {
            console.log(perfil.tx_funciones);
            $('#tx_rfc').val(perfil.tx_rfc);
            $('#razon_social').val(perfil.razon_social);
            $('#tx_folio_identificacion').val(perfil.tx_folio_identificacion);
            $('#tx_numero_empleado').val(perfil.tx_numero_empleado);
            $('#tx_puesto').val(perfil.tx_puesto);
            $('#id_tipo_identificacion option[value=' + perfil.id_tipo_identificacion + ']').prop('selected', true);
            $('#es_civil').prop('checked', perfil.es_civil);
            $('#es_comunitario').prop('checked', perfil.es_comunitario);
            $('#es_concesionario').prop('checked', perfil.es_concesionario);
            $('select[name^="hm"]').each(function (e) {
                $('#' + this.id + ' option[value="' + perfil[this.id] + '"]').prop('selected', true);
            });
            $('input[name^="st_full"]').each(function (e) {
                $('#' + this.id).prop('checked', +perfil[this.id]);
            });
            $('#tx_funciones_r').val(perfil.tx_funciones);
            muestra_CivilComunitarioConsecionario();

            muestra_cementerios(perfil.cementerios);

            if (perfil.identificacion != null && "tx_uuid" in perfil.identificacion) {
                var tx_uuid = perfil.identificacion.tx_uuid;
                crearBotonDescarga(tx_uuid, $($('#id_file_0018')[0].parentNode));
            }

            if (perfil.acta != null && "tx_uuid" in perfil.acta) {
                var tx_uuid = perfil.acta.tx_uuid;
                crearBotonDescarga(tx_uuid, $($('#id_file_0019')[0].parentNode));
            }

            perfil.oficios_archivo.forEach((e) =>
                crearBotonDescarga(e.archivo.tx_uuid, $($('#id_file_000' + e.id_tipo_oficio)[0].parentNode))
            );

        }


        window.alta_usuario = function () {
            $('.seluser').prop('disabled', false);
            $('.seluser').removeClass('disabled');
            $('#tx_folio_identificacion').focus();
        }

        if ($('#idUsuario').prop('disabled')) {
            /* se va a editar un usuario */
            $('#estatus').text('Guardar');
            $('#idUsuario :nth-child(2)').prop('selected', true).trigger('change');
        }

        $('#btn_cancelar').on('click', function (e) {
            $('#regresa').trigger('click');
        })

        if ($('.myaccount').text() !== PERMISO.NB_DIRGRAL) {
            $('input, select').prop('disabled', true);
            $('.NODIRGRAL').remove();
        }
    }

});
