$(document).ready(function() {
     $("input[type=file][id^='id_']").change(function(e){
                $(this).parent().next().addClass('d-none'); /* oculta el mensaje que falta cargar archivo */
                var nombre=this.files[0].name;
                if($(this).data('filesize') && (this.files[0].size > ($(this).data('filesize') * 1000000))){
                    alert('El archivo no debe superar los '+$(this).data('filesize')+' MB');
                    return false;
                }
                if (this.files[0].size > 10000000) {
                    alert('El archivo no debe superar los 10MB');
                    return false;
                }
                var url=$(this).data('url');
                var campo=$(this).data('id');

                  var id=this.id;
                  var quediv=$($("#l_" + this.id)[0].closest('div'));
                  var label=$($("#l_" + this.id)[0].closest('label'));
                  var archivo=$("#l_" + this.id)[0].id.split('_')[3];
                  var spinner='<i class="fas fa-spinner fa-spin"></i>';
                  label.addClass('d-flex align-items-center justify-content-around');
                  label.append(spinner);
                  label.find('p').html('Enviando un documento');
                  var Data1 = {
                        file : this.files[0],
                  };
                  var fd = new FormData();
                  fd.append(this.id,this.files[0]);
                  if (campo=='idUsuario' || campo=='idPrestador') {
                     fd.append(campo,$('#'+campo).val());
                     if ($(this).data('id_tipo_oficio')!=undefined) {
                        fd.append('id_tipo_oficio',$(this).data('id_tipo_oficio'));
                     }
                  } else {
                     fd.append(campo,$('#'+campo).data('id'));
                  }
                  $.ajax({
                       type: 'post',
                       url: ubase+'/'+url,
                       headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                       contentType: false,
                       processData: false,
                       cache:       false,
                       data : fd,
                       success: function(data){
                             quediv.find('label').find('i').addClass('d-none');
                             $("#l_"+id)[0].innerHTML=nombre;
                             crearBotonDescarga(data.data.tx_uuid,quediv,data.data.id);

                       },
                       error: function( jqXHR, textStatus, errorThrown ){
				      if(jqXHR.status === 401){
					__crearMensaje(true,"Error","Tu sesión ha caducado");
				      }
				      if(jqXHR.status === 418){
					__crearMensaje(true,"Error",data.mensaje||data.message);
				      }
				      __crearMensaje(true,"Error","No se pudo subir su archivo");

                       }
                    });
     });

     window._visualizar = function (e) {
        event.preventDefault();
        event.stopPropagation();
        var _this=this;
        var idFile = $(e.target)[0].parentNode.dataset.id;
        var uuid = $(e.target)[0].parentNode.dataset.uuid;
        $(this).hide();
        //$('.fa-spinner').show();
        $.ajax({
            url: ubase+'/cementeriosverarchivo',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({uuid: uuid,id:idFile}),
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            type: 'POST',
            async: true,
            success: function(data) {
                ahref=document.createElement('a');
                blob = b64toBlob(data.content, 'application/octet-stream');
                blobUrl = URL.createObjectURL(blob);
                ahref.setAttribute('href', blobUrl);
                ahref.setAttribute('download', data.nombre);
                ahref.click();

                //$('.fa-spinner').hide();
                $(_this).show();
            },
            error: function(jqXHR, textStatus, errorThrown) {
                switch(jqXHR.status){
                    case 419:{
                        err = 'Tu sesión ha caducado, por favor inicia sesión de nuevo';
                        break;
                    }
                    default:{
                        err = jqXHR.responseJSON.mensaje || jqXHR.responseJSON.message;
                        crearMensaje(true,"Error: ", err).then(function() {});
                        break;
                    }
                }
                alert(err);
                $('#btn-loader-pics-f-'+idFile).hide();
                $('#btn-view-pics-f-'+idFile).show();
            }
        });
     }

        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }

});

