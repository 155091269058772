document.addEventListener('DOMContentLoaded', () => {
    let radios_visitas = document.querySelectorAll('input.lugar_visitas');
    let _cementerio = document.getElementById('cementerioDiv');
    let _agencia = document.getElementById('agenciaDiv');
    var s_alcaldia = document.querySelector('select[name="id_alcaldia"]');
    var s_cementerio = document.querySelector('select[name="id_cementerio"]');
    var s_agencia = document.querySelector('select[name="id_agencia"]');
    radios_visitas.forEach((el) => {
        radioVisitas(el);
        el.addEventListener('click', (e) => {
            demarcacionCementerio(s_alcaldia, e.target);
            radioVisitas(e.target);
            s_alcaldia.selectedIndex = 0;
        });
    });

    // Bandeja: Supervisiones
    $('#bandeja form.form-supervisiones select#id_alcaldia').change(function () {
        if (this.value !== '') {
            // Si seleccionó una opción
            var _lugar_v = {
                value: '1'
            };
            demarcacionCementerio(s_alcaldia, _lugar_v);
            $('#id_cementerio').prop('disabled', false);

            _lugar_v.value = '2';
            demarcacionCementerio(s_alcaldia, _lugar_v);
            $('#id_agencia').prop('disabled', false);
        } else {
            $('#id_cementerio').prop('disabled', true).html('<option value="">Elige Cementerio</option>');
            $('#id_agencia').prop('disabled', true).html('<option value="">Elige Agencia Funeraria</option>');
        }
    });

    function radioVisitas(elem) {
        if (elem.checked && elem.value == '1') {
            _agencia.classList.add('d-none');
            _agencia.getElementsByTagName('select').id_agencia.required = false;
            _agencia.getElementsByTagName('select').id_agencia.selectedIndex = 0;
            _cementerio.classList.remove('d-none');
            _cementerio.getElementsByTagName('select').id_cementerio.required = true;
        } else if (elem.checked && elem.value == '2') {
            _cementerio.classList.add('d-none');
            _cementerio.getElementsByTagName('select').id_cementerio.required = false;
            _cementerio.getElementsByTagName('select').id_cementerio.selectedIndex = 0;
            _agencia.classList.remove('d-none');
            _agencia.getElementsByTagName('select').id_agencia.required = true;
        }
    }
    const _listFiles = ['acuerdo_supervision', 'oficio_supervision', 'acta_supervision']
    _listFiles.forEach((e) => {
        var _file = document.querySelector('input[name="archivo_' + e + '"]');
        if (_file) {
            _file.addEventListener('change', (el) => {
                el.target.parentElement.classList.remove('border', 'border-danger');
                _uploadSizeDb = el.target.dataset.uploadsize;
                _uploadTypeDb = el.target.dataset.uploadtype;

                var _fileName = el.target.files[0].name;
                var _fileSize = el.target.files[0].size;

                kb = _fileSize / 1024;
                __mfs = _uploadSizeDb * 1024

                if (!el.target.files[0].type.includes(_uploadTypeDb)) {
                    el.target.files = new DataTransfer().files;
                    el.target.value = null;
                    alert(_fileName + ', este archivo no será cargado porque no corresponde a una extensión valida.');
                } else if (kb > __mfs) {
                    el.target.files = new DataTransfer().files;
                    el.target.value = null;
                    alert(_fileName + ', este archivo no será cargado porque excede el tamaño permitido de ' + _uploadSizeDb + ' megas.');
                } else {
                    _file.nextElementSibling.innerHTML = _fileName;
                    _file.nextElementSibling.style.width = '195px';
                    _file.nextElementSibling.style.wordWrap = 'break-word';
                    let buttonPreview = _file.parentElement.children['buttonPreview'];
                    if (buttonPreview.getElementsByTagName('a')[0] == null) {
                        buttonPreview.classList.remove('d-none');
                        ahrefPreview = document.createElement('a');
                        blobUrl = URL.createObjectURL(el.target.files[0]);
                        ahrefPreview.innerText = 'Visualizar';
                        ahrefPreview.classList.add('d-inline', 'st-btn', 'green')
                        ahrefPreview.setAttribute('href', blobUrl);
                        ahrefPreview.setAttribute('target', '_blank')
                        ahrefPreview.setAttribute('download', _fileName);
                        buttonPreview.appendChild(ahrefPreview);
                        let buttonDelete = _file.parentElement.children['buttonDelete'];
                        buttonDelete.classList.remove('d-none');
                    }
                }
            });
        }
    });

    try {
        var val_lugar_visita = document.querySelector('.lugar_visitas:checked');
        if (val_lugar_visita != null && s_alcaldia != null) {
            demarcacionCementerio(s_alcaldia, val_lugar_visita);
            s_alcaldia.addEventListener('change', function (v) {
                demarcacionCementerio(v.target, document.querySelector('.lugar_visitas:checked'));
            });
        } else {
            if (s_alcaldia) {
                s_alcaldia.addEventListener('change', function (v) {
                    demarcacionCementerio(v.target, document.querySelector('.lugar_visitas:checked'));
                });
            }
        }
    } catch (error) {
        console.info(error);
    }

    function demarcacionCementerio(_alcaldia, _lugar_v) {
        if (_lugar_v != null && _lugar_v.value == '1' && _alcaldia != null && _alcaldia.selectedIndex != 0 && _alcaldia.value != '') {
            s_cementerio.innerHTML = null;
            let option = document.createElement('option');
            option.text = 'Elige Cementerio';
            option.value = '';
            option.disabled = true;
            option.selected = true;
            s_cementerio.appendChild(option);
            $.ajax({
                url: ubase + '/supervision/get-cementerio-by-alcaldia/' + _alcaldia.value,
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'GET',
                async: true,
                success: function (data) {
                    let oldcementerio = 0;
                    if (s_cementerio.dataset.oldcementerio) {
                        oldcementerio = s_cementerio.dataset.oldcementerio;
                    }
                    data.data.forEach((elc) => {
                        var option = document.createElement('option');
                        option.text = elc.nb_cementerio;
                        option.value = elc.id;
                        if (elc.id == oldcementerio) {
                            option.selected = true;
                        }
                        s_cementerio.appendChild(option);
                    });
                }
            });
        } else if (_lugar_v != null && _lugar_v.value == '2' && _alcaldia != null && _alcaldia.selectedIndex != 0 && _alcaldia.value != '') {
            s_agencia.innerHTML = null;
            let option = document.createElement('option');
            option.text = 'Elige Agencia';
            option.value = '';
            option.disabled = true;
            option.selected = true;
            s_agencia.appendChild(option);
            $.ajax({
                url: ubase + '/supervision/get-agencia-by-alcaldia/' + _alcaldia.value,
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'GET',
                async: true,
                success: function (data) {
                    let oldagencia = 0;
                    if (s_agencia.dataset.oldagencia) {
                        oldagencia = s_agencia.dataset.oldagencia;
                    }
                    data.data.forEach((elc) => {
                        var option = document.createElement('option');
                        option.text = elc.nb_agencia;
                        option.value = elc.id;
                        if (elc.id == oldagencia) {
                            option.selected = true;
                        }
                        s_agencia.appendChild(option);
                    });
                }
            });
        }
    }

    window.deleteFileSupervision = function (e) {
        e.parentElement.classList.add('d-none');
        e.parentElement.parentElement.children['buttonPreview'].classList.add('d-none');
        e.parentElement.parentElement.children['buttonPreview'].innerHTML = '';
        e.parentElement.parentElement.querySelector('p.texto').innerHTML = '<i class="fa fa-cloud-upload-alt mr-1"></i>Carga de archivos';
        e.parentElement.parentElement.querySelector('p.texto').style.width = 'auto';
        e.parentElement.parentElement.querySelector('p.texto').style.wordWrap = 'initial';
        e.parentElement.parentElement.querySelector('input[type="file"]').value = null;
        e.parentElement.parentElement.querySelector('input[type="file"]').files = new DataTransfer().files;
    }

    window.sendFormDataSupervision = function (e) {
        e.disabled = true;
        document.getElementById('formSupervision').submit();
    }

    window.showModalFormSupervision = function (e) {
        $('#registro-exitoso-supervision').modal('show');
        $("#registro-exitoso-supervision").on("hidden.bs.modal", function () {
            $.redirect(ubase + "/supervision", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });
    }

    window.showModalBorradoSupervisionVisita = function (e) {
        $('#borrado-exitoso-supervision-visita').modal('show');
        $("#borrado-exitoso-supervision-visita").on("hidden.bs.modal", function () {
            $.redirect(ubase + "/supervision", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });
    }

    $('#confirm-form-supervision').on('hidden.bs.modal', function () {
        let buttonFormGuardar = document.getElementById('buttonFormGuardar');
        if (buttonFormGuardar) {
            buttonFormGuardar.remove()
        }
        let buttonFormVisita = document.getElementById('buttonFormVisita');
        if (buttonFormVisita) {
            buttonFormVisita.remove();
        }
    });

    window.sendDataSup = function (_button) {
        var forms = document.getElementsByClassName('form-validation-supervision');
        _button.type = 'submit';
        if (_button && _button.value == 'Guardar') {
            let valid = Array.prototype.filter.call(forms, function (form) {
                form.classList.remove('was-validated');
            });
            _button.type = 'button';
            let hid_val = document.createElement('input');
            hid_val.type = 'hidden';
            hid_val.name = 'save_form';
            hid_val.value = 'save_form';
            hid_val.id = 'buttonFormGuardar';
            _button.appendChild(hid_val);
            $('#confirm-form-supervision').modal('show');
            return false;
        }
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                if (form.checkValidity() === true) {
                    if (_button.value == 'Registrar') {
                        form.submit();
                    } else {
                        _button.type = 'button';
                        let hid_val = document.createElement('input');
                        hid_val.type = 'hidden';
                        hid_val.name = 'add_visita';
                        hid_val.value = 'add_visita';
                        hid_val.id = 'buttonFormVisita';
                        _button.appendChild(hid_val);
                        $('#confirm-form-supervision').modal('show');
                        return false;
                    }
                } else {
                    __crearMensaje(true, "Error", "Por favor revisa los campos marcados con rojo.");
                    form.querySelectorAll('input').forEach((elem) => {
                        if (elem.type == 'file' && elem.required) {
                            if (!elem.checkValidity()) {
                                elem.parentElement.classList.add('border', 'border-danger');
                            }
                        } else if (elem.type == 'text' && elem.required) {
                            if (!elem.checkValidity()) {
                                elem.classList.add('is-invalid');
                            } else {
                                elem.classList.remove('is-invalid');
                                elem.classList.add('is-valid');
                            }
                        } else if (elem.type == 'date' && elem.required) {
                            if (!elem.checkValidity()) {
                                elem.classList.add('is-invalid');
                            } else {
                                elem.classList.remove('is-invalid');
                                elem.classList.add('is-valid');
                            }
                        }
                    });
                    form.querySelectorAll('select').forEach((elem) => {
                        if (!elem.checkValidity()) {
                            elem.classList.add('is-invalid');
                        } else {
                            elem.classList.remove('is-invalid');
                            elem.classList.add('is-valid');
                        }
                    });
                }
                form.classList.add('was-validated');
            }, false);
        });
    }

});
