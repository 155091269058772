//Validación por medio de expresiones regulares
//Nombres y apellidos
$(document).ready(function() {
    $(".names").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
    $(".letrasynumeros").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-Z0-9ñÑ\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

    $(".namessinacentos").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-ZñÑ\s. ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });


//Números como telefónicos o campos exclusivamente llenados con números
    $(".numbers").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[0-9-]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

    $(".noneg").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

//Permite carácteres propios del RFC y del CURP
//(sin carácteres especiales)
    $(".rfc-curp").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-Z\-0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

//Permite letras y números, pues sirven para los nombres de calles
//y números exteriores e interiores, permite algunos carácteres
//especiales como - . #
    $(".street-names").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-. ]*$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

//Restringe carácteres no propios de los correos
//electrónicos, como espacios y signos, exceptuando
//punto y guiones
     $(".email").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-Z0-9@.-_]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });


    //Números como telefónicos o campos exclusivamente llenados con números
        $(".coords").bind("keypress", function(event) {
            if (event.charCode != 0) {
                var regex = new RegExp("^[0-9-.]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });

//Permite letras y números, pues sirven para los nombres de calles
//y números exteriores e interiores, permite algunos carácteres
//especiales como - . # / ( )
    $(".names-all").bind("keypress", function(event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-!@#$&()\\-`. +,/\"]*$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });

    $(".textos").bind("keypress", function(event) {
        if (event.charCode != 0) {
            if (event.charCode == 13) {
                return "\ni\r";
            }
            var regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-!@$&()\\-`. +,/\"]*$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });


  $('input[type=number][max]:not([max=""])').on('input', function(ev) { //* a los campos number que tiene max los limita a su longitud"
    var $this = $(this);
    var maxlength = $this.attr('max').length;
    var value = $this.val();
    if (value && value.length >= maxlength) {
      $this.val(value.substr(0, maxlength));
    }
  });

  $(".mayus").bind("keypress", function(event) {
       e.value = e.value.toUpperCase();
  })


});

