$(document).ready(function () {
    if ($('#registrarRecuperacion').length) {
        $('.st-input').css('max-width', 'none'); /* quita la limitante del max-width y que se controle x bootstrap */

        $('#regresa').on('click', function (e) {
            $.redirect(ubase + "/recuperaciones", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });

        $('input[name="fa_notificado"]').on('change', function (e) {
            $('.error_notifica').addClass('d-none');
        });

        $('#familiarnotificado_1').on('click', function (e) {
            $('.senecesitanotificar').addClass('d-none');
        });

        $('#id_cementerio, #id_cementerio_espacio').on('change', function (e) {
            if (($('#id_cementerio').val() !== '' && $('#id_cementerio').val() !== null) &&
                ($('#id_cementerio_espacio').val() !== '' && $('#id_cementerio_espacio').val() !== null)
            ) {
                buscaClaveDeLugar();
            }
        });

        $('#id_lugar').on('change', function (e) {
            if (($('#id_cementerio').val() === '' || $('#id_cementerio').val() === null) &&
                $('#id_cementerio_espacio').val() === '' || $('#id_cementerio_espacio').val() === null
            ) {
                $('.error_lugardesignado').removeClass('d-none');
            }
        });

        $("#id_cementerio").on("changed.bs.select",
            function (e, clickedIndex, newValue, oldValue) {
                $('.s_cementerioobli').addClass('d-none');
            });

        window.buscaClaveDeLugar = function () {
            $('.error_lugardesignado').addClass('d-none');
            var Data1 = {};
            var method = 'get';
            if ($('#id_cementerio').val() != '') {
                Data1['id_cementerio'] = $('#id_cementerio').val();
            }
            if ($('#id_cementerio_espacio').val() != '') {
                Data1['id_cementerio_espacio'] = $('#id_cementerio_espacio option:selected').val();
            }
            //
            $('#id_lugar option').each(function () {
                if ($(this).val() !== '') {
                    $(this).remove();
                }
            });
            $.ajax({
                dataType: 'json',
                type: method,
                url: uGetSpaces,
                data: Data1,
                success: function (data) {
                    if(data.data.length > 0){
                        armaOpcionesClaveDeLugar(data.data)
                        if (typeof _recuperacion !== 'undefined') {
                            $('#id_lugar option[value="' + _recuperacion.id_lugar + '"]').prop('selected', true)
                        }
                    }else{
                        __crearMensaje(true, "Error", 'No hay espacios disponibles para recuperar');
                    }

                },

                error: function (jqXhr, textStatus, errorThrown) {
                    if (jqXhr.responseText.indexOf('duplicate key') != -1) {
                        $('#yaexistelugar').modal('show');
                    }
                }
            });
        }

        window.armaOpcionesClaveDeLugar = function (opciones) {
            $('#id_lugar').children().remove();
            $('#id_lugar').append('<option value="' + '" >Elige un lugar</option>');
            $.each(opciones, function (e) {
                $('#id_lugar').append('<option value="' + opciones[e].id + '" >' + opciones[e].tx_clave_ocupacion + '</option>');
            });
        }

        window.upd_dato = function () {
            var Data1 = {};
            var method = 'post';
            var url = '/creaRecuperacion';
            var forma = $('#frm_registrarecuperacion');
            var Data1 = forma.serialize();
            if ($('#frm_registrarecuperacion').data('id_recuperacion') != '') {
                var method = 'put';
                var url = '/updateRecuperacion';
                Data1 = Data1 + '&id=' + $('#frm_registrarecuperacion').data('id_recuperacion');
            }

            if ($('.visualizar').length > 0) {
                Data1 = Data1 + '&id_archivo_acta=' + $('.visualizar').data('id');
            }

            $.ajax({
                dataType: 'json',
                type: method,
                url: ubase + url,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    $('#registroexitoso').modal('show');
                    $("#registroexitoso").on("hidden.bs.modal", function () {
                        $.redirect(ubase + "/recuperaciones", {
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        });
                    })
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    if (jqXhr.responseJSON.message == 'CSRF token mismatch') {
                        location.href = "/";
                    }
                    alert('no se pudo registrar la recuperacion');
                }
            });
        }

        $('#estatus').on('click', function (e) {
            e.preventDefault();
            var forma = $('#frm_registrarecuperacion')[0];
            if (forma.checkValidity() === false ||
                $('input[type="file"].required:not([data-archivo])').length > 0 ||
                ($('#id_cementerio').val() === '' || $('#id__cementerio').val() === null) ||
                $('input[name="familiarnotificado"]:checked').length == 0 //||
                //$('#familiarnotificado_1')[0].checked == false
            ) {
                forma.classList.add('was-validated');

                if ($('input[type="file"].required:not([data-archivo])').length > 0) {
                    $('input[type="file"].required:not([data-archivo])').parent().next().removeClass('d-none');
                }

                if ($('#id_cementerio').val() === '' || $('#id_cementerio').val() === null) {
                    $('.s_cementerioobli').removeClass('d-none');
                }

                if ($('input[name="familiarnotificado"]:checked').length == 0) {
                    $('.error_notifica').removeClass('d-none');
                }else{
                    $('.error_notifica').addClass('d-none');
                }

                if ($('#familiarnotificado_1')[0].checked == false) {
                    $('.senecesitanotificar').removeClass('d-none');
                }
                return;
            }else{
                if ($('#familiarnotificado_1')[0].checked == false) {
                    $('.senecesitanotificar').removeClass('d-none');
                    $('#modalFamiliar').modal('show');
                    return;
                }
            }
            upd_dato(e);

        })


        var options = {
            ajax: {
                url: ubase + '/cementeriosbuscar',
                type: 'POST',
                dataType: 'json',
                data: function () { // This is a function that is run on every request
                    return {
                        q: '{{{q}}}'
                    }
                }
            },
            minLength: 4,
            liveSearch: true,
            locale: {
                emptyTitle: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                errorText: 'No se encontraron cementerios',
                statusSearching: 'Buscando ....',
                searchPlaceholder: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusInitialized: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusTooShort: 'Por favor Tecleé más dígitos',
                currentlySelected: 'Actualmente seleccionado',
                statusNoResults: 'No se encontraron cementerios'
            },
            log: 'error',
            preprocessData: function (data) {
                var i, l = data.length,
                    array = [];
                if (l) {
                    for (i = 0; i < l; i++) {
                        array.push($.extend(true, data[i], {
                            text: data[i].nb_cementerio + ',' + data[i].nb_municipio + ',' + data[i].nb_clasificacion,
                            value: data[i].id,
                            data: {
                                // subtext: data[i].estado
                            }
                        }));
                    }
                }
                return array;
            }
        };



        $(function () {
            $('#id_cementerio').selectpicker({
                liveSearch: true,
                styleBase: 'form-control form-control-cdmx'
            }).filter('.with-ajax').ajaxSelectPicker(options);
        });



        $('.fa-caret-square-down').on('click', function (e) {
            var anterior = $(this).prev();
            $(anterior).attr('size', $(anterior).children('option').length)
        });



        window.muestra_recuperacion = function (data) {
            $('input[id^="id_file_"]').next().next().remove(); /*remueve el boton de visualizar */
            $('input[id^="id_file_"]').removeAttr('data-archivo');; /*remueve el boton de visualizar */
            $('#id_cementerio_espacio option[value=""]').prop('selected', true)
            //$('.seluser').prop('disabled',true);
            $('#fh_ultima_inhumacion').val(data.fh_ultima_inhumacion);
            $('#fh_ultimo_pago').val(data.fh_ultimo_pago);
            $('#fh_gaceta').val(data.fh_gaceta);
            muestra_picker(data.cementerio);
            if (data.archivo_acta != null && "tx_uuid" in data.archivo_acta) {
                var tx_uuid = data.archivo_acta.tx_uuid;
                crearBotonDescarga(tx_uuid, $($('#id_file_0018')[0].parentNode), data.archivo_acta.id);
            }
            $('#id_cementerio_espacio option[value="' + data.id_cementerio_espacio + '"]').prop('selected', true)
            if (data.familiarnotificado == false) {
                $('#familiarnotificado_0').prop('checked', true)
            }
            if (data.familiarnotificado == true) {
                $('#familiarnotificado_1').prop('checked', true)
            }
            buscaClaveDeLugar();

        }

        window.muestra_picker = function (data) {
            if (data != null) {
                if ($("#id_cementerio option[value='" + data.id + "']").length == 0) {
                    $('#id_cementerio').append('<option value="' + data.id + '" selected="selected">' +
                        data.nb_cementerio + ', ' + data.alcaldia.nb_municipio + ', ' + data.clasificacion.nb_clasificacion + '</option>').selectpicker('refresh');
                }
                var text = $("#id_cementerio option[value='" + data.id + "']").text();
                $($('#id_cementerio')[0].parentNode).find('.filter-option-inner-inner').text(text);
            } else {
                $('#id_cementerio').find('option').remove();
                $($('#id_cementerio')[0].parentNode).find('.filter-option-inner-inner').text('')
                $('.dropdown-item.active.selected').children().remove();
            }
        }

        window.alta_usuario = function () {
            $('.seluser').prop('disabled', false);
            $('.seluser').removeClass('disabled');
            $('#tx_folio_identificacion').focus();
        }

        if ($('#idRecuperacion').prop('disabled')) {
            /* se va a editar un usuario */
            $('#estatus').text('Guardar');
            $('#idRecuperacion :nth-child(2)').prop('selected', true).trigger('change');
        }

        $('#btn_cancelar').on('click', function (e) {
            $('#regresa').trigger('click');
        })

        if (typeof _recuperacion !== 'undefined') {
            muestra_recuperacion(_recuperacion);
            $('#estatus').text('Guardar');
            $('.desrol').text('Editar Recuperación');
            $('#frm_registrarecuperacion').data('id_recuperacion', _recuperacion.id);
        }


    }

});
