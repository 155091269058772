$(document).ready(function() {
	if ($('#ocupacionesbandejaSelect').length) {

		    $('#regresa').on('click', function (e) {
				$.redirect(ubase+"/home",
					{  '_token': $('meta[name="csrf-token"]').attr('content') });
		    });

		 $('#id_alcaldia').change(function () {
				dame_cementerioxAlc();
		 })

		 if ($('.myaccount').text()!==PERMISO.NB_DIRGRAL) {
		     $('.div_demarcacion').remove();
		 }


		 $('#seleccionar').on('click', function (e) {
				  event.preventDefault();
				  var forma=$('#frmbandejabandejaocupacionesSelect')[0];
				  if (forma.checkValidity() === false) { 
				     forma.classList.add('was-validated');
				     return; 
				  }
				  $.redirect(ubase+"/cementeriosocupaciones",
						{  '_token': $('meta[name="csrf-token"]').attr('content'), 'id_cementerio':$('#id_cementerio').val() });
		 });

	}
});
