$(document).ready(function () {
    if ($('#registrarPrestador').length) {
        $('.st-input').css('max-width', 'none'); /* quita la limitante del max-width y que se controle x bootstrap */
        var opciones = '<option value="">Seleccionar</option>';
        prestadores.forEach((e) =>
            opciones += "<option data-data='" + JSON.stringify(e) + "' value=" + e.id + ">" + e.tx_email + "</option>"
        );

        $('#idPrestador').append(opciones);
        $('#idPrestador').focus();

        $('input:not(input[type="file"]), select:not([name="idPrestador"]), textarea').on('change', function (e) {
            upd_dato(e);
        });

        $('#regresa').on('click', function (e) {
            $.redirect(ubase + "/prestadores", {
                '_token': $('meta[name="csrf-token"]').attr('content')
            });
        });


        $('select[name^="hm_"], input[name^="st_full"]').on('change', function (e) {
            $('.error_horario').addClass('d-none');
            if (e.target.id.indexOf('abre') != -1) {
                var abre = e.target.value.replace(':', '');
                scierra = e.target.id.replace('abre', 'cierra');
                var cierra = $('#' + scierra).val();
                cierra = cierra.replace(':', '');
            }
            if (e.target.id.indexOf('cierra') != -1) {
                var cierra = e.target.value.replace(':', '');
                sabre = e.target.id.replace('cierra', 'abre');
                var abre = $('#' + sabre).val();
                abre = abre.replace(':', '');
                // console.log('cierra=' + cierra + ' abre=' + abre);
            }

            upd_dato(e, 'cementerio_horario');
        });


        window.upd_dato = function (e, estatus = '') {
            var Data1 = {};
            var quedato = e.currentTarget.id;
            var valor = e.currentTarget.value;

            if (e.currentTarget.type == "checkbox") {
                valor = e.currentTarget.checked;
            }

            if (e.currentTarget.type == 'radio') {
                quedato = e.currentTarget.name;
            }

            Data1[quedato] = valor;
            Data1['id'] = $('#idPrestador').val();

            if (e.currentTarget.name == 's_cementerio') {
                $('.s_cementerioobli').addClass('d-none');
                Data1['id_cementerio'] = valor;
                delete Data1[quedato];
            }

            $.ajax({
                dataType: 'json',
                type: 'post',
                url: ubase + '/actualizaPrestadorCementerio',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: Data1,
                success: function (data) {
                    if (e.currentTarget.type == "button") {
                        $('#registroexitoso').modal('show');
                        $("#registroexitoso").on("hidden.bs.modal", function () {
                            $.redirect(ubase + "/prestadores", {
                                '_token': $('meta[name="csrf-token"]').attr('content')
                            });
                        })
                    }
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    var errores = jqXhr.responseJSON.errors;
                    for (var x in errores) {
                        crearMensaje(true, "Error", errores[x]);
                        break;
                    }
                }
            });
        }

        $('#estatus').on('click', function (e) {
            e.preventDefault();
            var forma = $('#frm_registraprestador')[0];
            var ok_horario = horarios_correctos();
            if (forma.checkValidity() === false || ok_horario.estatus == false ||
                $('input[type="file"].required:not([data-archivo])').length > 0 ||
                ($('#s_cementerio').val() == '' || $('#s_cementerio').val() == null)
            ) {
                forma.classList.add('was-validated');

                if (ok_horario.estatus == false) {
                    $('.error_horario').html(ok_horario.descripcion);
                    $('.error_horario').removeClass('d-none');
                }

                if ($('input[type="file"].required:not([data-archivo])').length > 0) {
                    $('input[type="file"].required:not([data-archivo])').parent().next().removeClass('d-none');
                }

                if ($('#s_cementerio').val() == '' || $('#s_cementerio').val() == null) {
                    $('.s_cementerioobli').removeClass('d-none');
                }


                return;
            }
            upd_dato(e);

        })

        window.horarios_correctos = function () {
            var regresa = {
                estatus: false,
                descripcion: 'Al menos debe definir un dia en el horario.'
            };
            /* si encuentre un valor diferente de cero es correcto */
            $('select[name^="hm_"] option:selected').each(function (index) {
                if ($(this).val() != '0:00') {
                    regresa.estatus = true;
                    return false;
                }
            });

            //if (regresa.estatus==true) {
            /* si hay un checked full es correcto */
            if ($('input[name^="st_full"]:checked').length > 0) {
                regresa.estatus = true;
            }
            //}

            if (regresa.estatus == true) {
                $('select[name^="hm_abre"]').each(function (index) {
                    var abre = $(this).val().replace(':', '');
                    var scierra = $(this)[0].id.replace('abre', 'cierra');
                    var cierra = $('#' + scierra).val();
                    cierra = cierra.replace(':', '');
                    /* si encuentra que una apertura es mayor que el cierre hay error */
                    if (parseInt(abre) > parseInt(cierra)) {
                        $(this).focus()
                        regresa.estatus = false;
                        regresa.descripcion = "La apertura es mayor que el cierre de " + $(this)[0].id.replace('hm_abre_', '');
                        return false;
                    }
                });
            }

            return regresa;
        }

        window.error_horario = function (e) {
            $('#error_horario').remove()
            str = '<div class="modal fade" id="error_horario" tabindex="-1" data-backdrop="static" role="dialog" aria-hidden="true">' +
                '<div class="modal-dialog modal-sm" role="document">' +
                '<div class="modal-content rounded-modal" >' +
                '<div class="modal-body text-center text-danger">' +
                'Error en el horario' +
                '</div>' +
                '<div class="modal-footer justify-content-center border-0">' +
                '<div type="button" class="btn btn-pine-gree" data-dismiss="modal" >Cerrar</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            $('body').append(str);
            $('#error_horario').modal('show')
            $("#error_horario").on("hidden.bs.modal", function () {
                $(e.target).focus();
            });
        }




        var options = {
            ajax: {
                url: ubase + '/cementeriosbuscar',
                type: 'POST',
                dataType: 'json',
                data: function () { // This is a function that is run on every request
                    return {
                        q: '{{{q}}}',
                        civil: ($('#es_civil').prop('checked') == true & $('.desrol').text() == PERMISO.RESPONSABLE ? '1' : '0'),
                        concesionario: ($('#es_concesionario').prop('checked') == true & $('.desrol').text() == PERMISO.RESPONSABLE ? '1' : '0'),
                        comunitario: ($('#es_comunitario').prop('checked') == true & $('.desrol').text() == PERMISO.RESPONSABLE ? '1' : '0')
                    }
                }
            },
            minLength: 4,
            liveSearch: true,
            locale: {
                emptyTitle: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                errorText: 'No se encontraron cementerios',
                statusSearching: 'Buscando ....',
                searchPlaceholder: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusInitialized: 'Tecleé el nombre del cementerio o de la Demarcación Territorial',
                statusTooShort: 'Por favor Tecleé más dígitos',
                currentlySelected: 'Actualmente seleccionado',
                statusNoResults: 'No se encontraron cementerios'
            },
            log: 'error',
            preprocessData: function (data) {
                var i, l = data.length,
                    array = [];
                if (l) {
                    for (i = 0; i < l; i++) {
                        array.push($.extend(true, data[i], {
                            text: data[i].nb_cementerio + ',' + data[i].nb_municipio + ',' + data[i].nb_clasificacion,
                            value: data[i].id,
                            data: {
                                // subtext: data[i].estado
                            }
                        }));
                    }
                }
                return array;
            }
        };



        $(function () {
            $('#s_cementerio').selectpicker({
                liveSearch: true,
                styleBase: 'form-control form-control-cdmx'
            }).filter('.with-ajax').ajaxSelectPicker(options);
        });

        $('#idPrestador').on('change', function (e) {
            muestra_prestador(e);
        });


        $('.fa-caret-square-down').on('click', function (e) {
            var anterior = $(this).prev();
            $(anterior).attr('size', $(anterior).children('option').length)
        });



        window.que_rol = function (div) {
            if ($('.desrol').text() == PERMISO.ADMINDES) {
                $('.' + div + ' .administrador').removeClass('d-none').prop('required', true);
                $('.' + div + ' .responsable').addClass('d-none').prop('required', false);
                return;
            }
            if ($('.desrol').text() == PERMISO.RESPONSABLEDES) {
                $('.' + div + ' .responsable').removeClass('d-none').prop('required', true);
                $('.' + div + ' .administrador').addClass('d-none').prop('required', false);
                return;
            }
        }

        window.muestra_prestador = function (e) {
            $('input[id^="id_file_"]').next().next().remove(); /*remueve el boton de visualizar */
            $('input[id^="id_file_"]').removeAttr('data-archivo');; /*remueve el boton de visualizar */
            $('select[name^="hm"]').each(function (e) {
                $('#' + this.id + ' option[value="0:00"]').prop('selected', true);
            });
            $('#id_tipo_identificacion option[value=""]').prop('selected', true)
            if (e.target.value == "") {
                $('.seluser').prop('disabled', true);
                $('.seluser').addClass('disabled');
                return;
            }
            var data = $('#idPrestador option:selected').data('data');
            var tx_curp = data.tx_curp == null ? 'Sin curp' : data.tx_curp;
            $('#tx_curp').val(tx_curp);
            $('#nb_prestador_servicios').val(data.nb_prestador_servicios);
            $('#tx_apellido_paterno').val(data.tx_apellido_paterno);
            $('#tx_apellido_materno').val(data.tx_apellido_materno);
            $('#tx_telefono').val(data.tx_telefono);

            muestra_perfil(data);
            muestra_picker(data.cementerio);

            alta_usuario();
        }

        window.muestra_picker = function (data) {
            if (data != null) {
                if ($("#s_cementerio option[value='" + data.id + "']").length == 0) {
                    $('#s_cementerio').append('<option value="' + data.id + '" selected="selected">' +
                        data.nb_cementerio + ', ' + data.alcaldia.nb_municipio + ', ' + data.clasificacion.nb_clasificacion + '</option>').selectpicker('refresh');
                }
                var text = $("#s_cementerio option[value='" + data.id + "']").text();
                $($('#s_cementerio')[0].parentNode).find('.filter-option-inner-inner').text(text);
            } else {
                $('#s_cementerio').find('option').remove();
                $($('#s_cementerio')[0].parentNode).find('.filter-option-inner-inner').text('')
                $('.dropdown-item.active.selected').children().remove();
            }
        }


        window.muestra_perfil = function (perfil) {

            $('#tx_folio_licencia').val(perfil.tx_folio_licencia);
            $('#tx_actividades').val(perfil.tx_actividades);

            $('select[name^="hm"]').each(function (e) {
                $('#' + this.id + ' option[value="' + perfil[this.id] + '"]').prop('selected', true);
            });
            $('input[name^="st_full"]').each(function (e) {
                $('#' + this.id).prop('checked', +perfil[this.id]);
            });


            if (perfil.archivo_licencia != null && "tx_uuid" in perfil.archivo_licencia) {
                var tx_uuid = perfil.archivo_licencia.tx_uuid;
                crearBotonDescarga(tx_uuid, $($('#id_file_0018')[0].parentNode));
            }


        }

        window.alta_usuario = function () {
            $('.seluser').prop('disabled', false);
            $('.seluser').removeClass('disabled');
            $('#tx_folio_identificacion').focus();
        }

        if ($('#idPrestador').prop('disabled')) {
            /* se va a editar un usuario */
            $('#estatus').text('Guardar');
            $('#idPrestador :nth-child(2)').prop('selected', true).trigger('change');
        }

        $('#btn_cancelar').on('click', function (e) {
            $('#regresa').trigger('click');
        })


    }

});
