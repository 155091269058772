$(document).ready(function() {
  if ($('#cementeriosbandeja').length) {

         window.arma_botones = function (ren) {
                       var edicion='<div type="button" class="btn btn-pine-gree vercementerio" >Ver</div>';
                       //var ocupaciones='<div type="button" class="btn btn-pine-gree ocupaciones" >Ocupaciones</div>';
                       var ocupaciones='';
                       return '<td class="d-flex justify-content-between">'+
                       edicion+ocupaciones
                       '</td>';
        };

        $('#regresa').on('click', function (e) {
                                $.redirect(ubase+"/home",
                                        {  '_token': $('meta[name="csrf-token"]').attr('content') });
        });


	var discementerios = {
					 'tx_clave' : { header : 'Clave del Cementerio', 'class' : 'font-weight-bold' }
				       , 'id' : { header : 'id_cementerio', 'class' : 'd-none' }
				       , 'nb_cementerio' : { header : 'Nombre de Cementerio', 'class' : 'font-weight-bold' }
				       , 'Updated' : { header : 'Fecha de registro', 'class' : 'font-weight-bold' }
				       , 'nb_clasificacion' :  { header : 'Tipo de Cementerio', 'class' : 'font-weight-bold' }
				       , 'desTieneHornos' : { header : 'Hornos Crematorios', 'class' : 'font-weight-bold' }
				       , 'nb_municipio' : { header : 'Demarcación Territorial', 'class' : 'font-weight-bold' }
				       , 'userRegistro' : { header : 'Persona que registra', 'class' : 'font-weight-bold' }
				       , 'desEstatus' : { header : 'Estatus', 'class' : 'font-weight-bold' }
				       , 'ver' : { header : 'Acciones', 'armacol' : 'arma_botones(tr)' }
			 };


         window.ver_cementerio = function (e) {
                        var id_cementerio = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
                        $.redirect(ubase+"/cementeriosver",
                                { id: id_cementerio, '_token': $('meta[name="csrf-token"]').attr('content') });
         }

         window.ver_cementerio_ocupaciones = function (e) {
                        var id_cementerio = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
                        $.redirect(ubase+"/cementeriosocupaciones",
                                { id: id_cementerio, '_token': $('meta[name="csrf-token"]').attr('content') });
         }

         var _ce = _cementerios;
         armadatagrid(_cementerios,discementerios,'dg_cementerios',true);

         $('.vercementerio').on('click', function (e) {
              ver_cementerio(e)
         });

         $('.ocupaciones').on('click', function (e) {
              ver_cementerio_ocupaciones(e)
         });

         $('#filtrar').on('click', function (e) {
              e.preventDefault();
              filtrar(e)
         });

         $('#limpiar').on('click', function (e) {
              e.preventDefault();
              armadatagrid(_ce,discementerios,'dg_cementerios',true);
              $('#frmbandejacementerio')[0].reset();
         });

         window.filtrar = function() {
                                  var Data1 = {};
                                  var method='get';
                                  if ($('#nb_cementerio').val()!='') {
                                      Data1['nb_cementerio'] = $('#nb_cementerio').val();
                                  }
                                  if ($('#tx_clave').val()!='') {
                                      Data1['tx_clave'] = $('#tx_clave').val();
                                  }
                                  if ($('#id_alcaldia option:selected').val()!='') {
                                      Data1['id_alcaldia'] = $('#id_alcaldia option:selected').val();
                                  }
                                  if ($('#id_clasificacion option:selected').val()!='') {
                                      Data1['id_clasificacion'] = $('#id_clasificacion option:selected').val();
                                  }
                                  if ($('#estatus').val()!='') {
                                      Data1['estatus'] = $('#estatus option:selected').val();
                                  }
                                  $.ajax({
                                            dataType: 'json',
                                            type: method,
                                            url: ubase+'/cementeriosfiltrar',
                                            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                                            data: Data1,
                                            success: function(data){

                                                 _cementerios=data.data;
                                                  _cementerios.forEach(cementerio => { cementerio.nb_municipio = cementerio.alcaldia.nb_municipio; cementerio.nb_clasificacion = cementerio.clasificacion.nb_clasificacion });
                                                 armadatagrid(_cementerios,discementerios,'dg_cementerios',true);
						 $('.vercementerio').on('click', function (e) {
						      ver_cementerio(e)
						 });
						 $('.ocupaciones').on('click', function (e) {
						      ver_cementerio_ocupaciones(e)
						 });

                                            } ,

                                            error: function( jqXhr, textStatus, errorThrown ){
                                                  if (jqXhr.responseText.indexOf('duplicate key')!=-1) {
                                                          $('#yaexistelugar').modal('show');
                                                  }
                                            }
                                 });

         }


  }
});
