 /*
  *              parametro1 =json del armado de columnas
  *              parametro2 =cuando es una subtabla indica sobre cual renglon pone la nueva tabla
  *              parametro3 =sobre que nodo se agrega la tabla
  */

 window.armadatagrid_titulos = function (dis, trp, quedg) {
     var dg = $('#__sd');
     if (dg[0] != undefined) {
         var pn = dg[0].parentNode;
         pn.removeChild(dg[0])
     }
     tr = document.createElement('tr'); /* crea tr para un sub data grid */
     tr.setAttribute('id', '__sd');
     tr.setAttribute('data-pnid', trp.id); /* id del node parent */
     thp = document.createElement('th');
     thp.setAttribute('colspan', '6');

     ta = document.createElement('table');
     ta.setAttribute('class', 'tabla-inmuebles');
     thead = document.createElement('thead');
     tbody = document.createElement('tbody');
     tbody.setAttribute('id', quedg + '_body');
     trx = document.createElement('tr');

     for (var y in dis) {
         th = document.createElement('th');
         tdCelda = document.createTextNode(dis[y].header);
         if ('class' in dis[y]) {
             th.setAttribute("class", dis[y].class);
         }
         th.appendChild(tdCelda);
         trx.appendChild(th);
     }

     //thead.appendChild(trx);
     thead.appendChild(trx);
     if (!trp) {
         var dg = $('#' + quedg);
         dg.empty();
         dg[0].appendChild(thead);
         dg[0].appendChild(tbody);
     } else {
         ta.appendChild(thead);
         ta.appendChild(tbody);
         thp.appendChild(ta);
         tr.appendChild(thp);
         $(tr).insertAfter(trp);
     }
     return $(tbody);
 };

 /*
     parametro 1=arreglo de registrso a desplegar
     parametro 2=json del armado de columnas
     parametro 3=id del elemento donde va a crear la tabla
     parametro 4=indica si arma los titulos
     parametro 5=cuando es una subtabla indica sobre cual renglon pone la nueva tabla

 */
 window.armadatagrid = function (regs, dis = null, quedg = 'dg_autorizacion', titulos = false, ren = false) {
     if (titulos) {
         dg = armadatagrid_titulos(dis, ren, quedg)
     } else {
         var dg = $('#' + quedg + " tbody");
         while (dg[0].firstChild) {
             dg[0].removeChild(dg[0].firstChild);
         }
     }
     var tr = '';
     var td = '';
     var campos = '';
     var rx = 0;
     for (var x in regs) {
         /* arma los renglones detalle del datagrid */
         rx += 1;
         //console.log(regs[x]);
         tr = document.createElement('tr');
         tr.setAttribute('id', '_' + regs[x].id + '_' + quedg + '_' + regs[x].idinfractor);
         tr.setAttribute('data-id', regs[x].id);
         dg[0].appendChild(tr)
         campos = regs[x];
         for (var y in dis) {
             if ('armacol' in dis[y]) {
                 td = eval(dis[y].armacol);
                 $(tr).append(td);
                 $('[data-toggle="tooltip"]').tooltip({
                     template: '<div class="tooltip tooltip-info"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
                 });
                 continue;
             }
             //console.log('y='+y+' campos'+campos[y]+' y in campos'+(y in campos));
             if (campos.hasOwnProperty(y)) {
                 //console.log('va a armar el campo'+y);
                 td = document.createElement('td');
                 p = document.createElement('p');
                 tdCelda = document.createTextNode(campos[y]);
                 if ('class' in dis[y]) {
                     td.setAttribute("class", dis[y].class);
                     p.setAttribute("class", dis[y].class.indexOf('(') == -1 ? dis[y].class : eval(dis[y].class));
                 }
                 p.appendChild(tdCelda);
                 td.appendChild(p);
                 tr.appendChild(td);
                 if ('boton' in dis[y]) {
                     //td=document.createElement('td');
                     bt = document.createElement('button');
                     bt.setAttribute('type', 'button');
                     if ('classb' in dis[y]) {
                         bt.setAttribute("class", dis[y].classb.indexOf('(') == -1 ? dis[y].classb : eval(dis[y].classb));
                     }
                     bt.setAttribute('onclick', dis[y].funcion + '(' + tr.id + ')');
                     td.appendChild(bt);
                 }
             } else {
                 if ('boton' in dis[y]) {
                     td = document.createElement('td');
                     bt = document.createElement('button');
                     bt.setAttribute('type', 'button');
                     if ('classb' in dis[y]) {
                         bt.setAttribute("class", dis[y].classb.indexOf('(') == -1 ? dis[y].classb : eval(dis[y].classb));
                     }
                     bt.setAttribute('onclick', dis[y].funcion + '(' + tr.id + ')');
                     td.appendChild(bt);
                     tr.appendChild(td);
                 }
             }
         }
     }
 }
