                window.dame_cementerioxAlc = function () {
                                  $.ajax({
                                            dataType: 'json',
                                            type: 'get',
                                            url: ubase+'/damecementerioxAlc/'+$('#id_alcaldia').val(),
                                            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},

                                            success: function(data){
                                                    $('#id_cementerio option').remove();
                                                    $('<option>').val("").text("Seleccione un cementerio").appendTo('#id_cementerio');
                                                    var cem=data.data;
                                                    for(var x in cem) {
                                                        $('<option>').val(cem[x].id).data('nb_clasificacion',cem[x].clasificacion.nb_clasificacion).data('tx_clave',cem[x].tx_clave).data('is_historico',cem[x].is_historico).data('is_artistico',cem[x].is_artistico).data('tx_calle',cem[x].tx_calle).data('tx_num_ext',cem[x].tx_num_ext).data('tx_codigo_postal',cem[x].tx_codigo_postal).data('des_colonia',cem[x].colonia.nb_asentamiento).data('des_delegacion',cem[x].alcaldia.nb_municipio).data('modalidades',cem[x].modalidades).text(cem[x].nb_cementerio).appendTo('#id_cementerio');
                                                    }
                                            } ,

                                            error: function( jqXhr, textStatus, errorThrown ){
                                                  var errores=jqXhr.responseJSON.errors;
                                                  for (var x in errores) {
                                                        crearMensaje(true,"Error", errores[x]);
                                                        break;
                                                 }
                                            }
                                 });
                }

