/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// Boostrap Core
require('./bootstrap');


// Datatables
var dt = require('datatables.net');
var buttons = require('datatables.net-buttons');

// Global
var token = document.head.querySelector('meta[name="csrf-token"]');
var timercito;
import 'bootstrap-select'
import 'ajax-bootstrap-select';

// JS Arquetipo
require('./engine');
require('./appservices');
require('./livewire'); // FT
require('./components/armadatagrid');
require('./components/crearBotonDescarga');
require('./components/registrarUsuario');
require('./components/registrarPrestador');
require('./components/registrarOcupacion');
require('./components/registrarRecuperacion');
require('./components/registrarIncidencia');
require('./components/registrarCementerio');
require('./components/cementeriosbandeja');
require('./components/ocupacionesbandeja');
require('./components/incidenciasbandeja');
require('./components/usuariosbandeja');
require('./components/prestadoresbandeja');
require('./components/recuperacionesbandeja');
require('./components/constantes');
require('./components/validation');
require('./components/subearchivos');
require('./components/registrarOcupacion');
require('./components/ocupacionesbandejaSelect');
require('./components/cementerioxAlc');
require('./components/menu');
require('./components/supervisiones');
require('./components/incidencias');
require('./components/form-by-rol'); // FT

require('./opservfun');

import "jquery.redirect/jquery.redirect";
