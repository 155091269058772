if ($('#ocupacionesbandeja').length) {

    window.arma_botones = function (ren) {
        var edicion='<div type="button" class="btn btn-pine-gree verocupacion" >Ver</div>';
        //var ocupaciones='<div type="button" class="btn btn-pine-gree ocupaciones" >Ocupaciones</div>';
        return '<td class="d-flex justify-content-between">'+
        edicion
        '</td>';
    };

    $('#regresa').on('click', function (e) {
        $.redirect(ubase + "/cementeriosocupacionesSelect", {
            '_token': $('meta[name="csrf-token"]').attr('content')
        });
    });

    var disocupaciones = {
        'tx_clave_ocupacion': {
            header: 'Clave de Lugar Asignado',
            'class': 'font-weight-bold'
        },
        'id': {
            header: 'id_cementerio',
            'class': 'd-none'
        },
        'tp_espacio': {
            header: 'Tipo de Lugar',
            'class': 'font-weight-bold'
        },
        'Updated': {
            header: 'Fecha de registro',
            'class': 'font-weight-bold'
        },
        'nb_cementerio': {
            header: 'Nombre de Cementerio',
            'class': 'font-weight-bold'
        },
        'nb_municipio': {
            header: 'Demarcación Territorial',
            'class': 'font-weight-bold'
        },
        'nu_ocupa_actual': {
            header: 'Ocupación Actual',
            'class': 'font-weight-bold'
        },
        'nu_disponibilidad': {
            header: 'Disponibilidad',
            'class': 'font-weight-bold'
        },
        'userRegistro': {
            header: 'Persona que registra',
            'class': 'font-weight-bold'
        },
        'ver': {
            header: 'Acciones',
            'armacol': 'arma_botones(tr)'
        }
    };

    $('input[type=text]').change(function () {
        return this.value.toUpperCase();
    })


    window.ver_ocupacion = function (e) {
        var id_cementerio = e.target.parentNode.parentNode.getElementsByTagName('td')[1].innerText;
        var id_ocupacion = e.target.parentNode.parentNode.dataset.id;
        $.redirect(ubase + "/cementeriosocupacionver", {
            'id_cementerio': $('#nb_cementerio').data('id'),
            'id_ocupacion': id_ocupacion,
            '_token': $('meta[name="csrf-token"]').attr('content')
        });
    }


    armadatagrid(_ocupaciones, disocupaciones, 'dg_ocupaciones', true);

    $('.verocupacion').on('click', function (e) {
        ver_ocupacion(e)
    });


    $('#filtrar').on('click', function (e) {
        e.preventDefault();
        filtrar(e)
    });

    $('#registrar').on('click', function (e) {
        e.preventDefault();
        registrar(e)
    });

    $('#limpiar').on('click', function (e) {
        e.preventDefault();
        $('#frmbandejabandejaocupaciones')[0].reset();
    });

    window.filtrar = function () {
        var Data1 = {};
        var method = 'get';
        if ($('#nb_cementerio').val() != '') {
            Data1['nb_cementerio'] = $('#nb_cementerio').val();
        }
        if ($('#tx_clave_ocupacion').val() != '') {
            Data1['tx_clave_ocupacion'] = $('#tx_clave_ocupacion').val();
        }
        if ($('#id_tipo_espacio option:selected').val() != '') {
            Data1['id_tipo_espacio'] = $('#id_tipo_espacio option:selected').val();
        }
        $.ajax({
            dataType: 'json',
            type: method,
            url: ubase + '/cementeriosocupacionesfiltrar',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: Data1,
            success: function (data) {

                _ocupaciones = data.data;
                armadatagrid(_ocupaciones, disocupaciones, 'dg_ocupaciones', true);
                $('.verocupacion').on('click', function (e) {
                    ver_ocupacion(e)
                });
            },

            error: function (jqXhr, textStatus, errorThrown) {
                if (jqXhr.responseText.indexOf('duplicate key') != -1) {
                    $('#yaexistelugar').modal('show');
                }
            }
        });

    }

    window.registrar = function () {
        $.redirect(ubase + "/cementeriosocupacionesregistrar", {
            id_cementerio: $('#nb_cementerio').data('id'),
            '_token': $('meta[name="csrf-token"]').attr('content')
        });
    }
}
